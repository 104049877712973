/* Round Select
----------------------------------------------------------------------------------------*/

.full-content-round-select {
  position: relative;
  display: inline-block;
}

.full-content-round-select::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 300%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.content-round-select-like-button:hover,
.content-round-select:hover,
.full-content-round-select:hover {
  z-index: 19 !important;
}

.content-round-select-like-button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.full-content-round-select .content {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  padding-top: 5px;
  box-sizing: border-box;
}
.full-content-round-select .content .dot {
  position: relative;
  margin: 2px auto;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #b4b4b4;
}

.full-content-round-select.light-blue .content {
  background-color: transparent;
}

.full-content-round-select.light-blue:hover .content {
  background-color: #e2ebfd;
}

.full-content-round-select:hover .content .dot,
.full-content-round-select.opened .content .dot {
  background-color: #3a79e6;
}
.full-content-round-select > .content-list {
  position: relative;
  padding-top: 4px;
}
.content-round-select-like-button .full-content-round-select {
  top: 4px;
}
.content-round-select-like-button .full-content-round-select > .content-list {
  padding-top: 8px;
}
.full-content-round-select > .content-list > .list {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  width: 160px;
  margin-left: -65px;
  padding: 5px 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  z-index: 17;
}
.full-content-round-select.right > .content-list > .list {
  margin-left: 0 !important;
  right: 0 !important;
}
@media only screen and (max-width: 1240px) {
  .full-content-round-select > .content-list > .list {
    margin-left: 0 !important;
    right: 0 !important;
  }
}

.full-content-round-select:hover > .content-list > .list,
.full-content-round-select.opened > .content-list > .list {
  opacity: 1;
  visibility: visible;
}

.full-content-round-select > .content-list > .list ul {
  position: relative;
  list-style-type: none;
}
.full-content-round-select > .content-list > .list ul li {
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  line-height: 40px;
  padding: 0 16px;
  color: #000;
  font-size: 15px;
  opacity: 1;
  font-weight: 400;
  background-color: #fff;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
}

.full-content-round-select > .content-list > .list ul li .content-loader {
  text-align: center;
}
.full-content-round-select
  > .content-list
  > .list
  ul
  li
  .content-loader
  .loader-button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.full-content-round-select > .content-list > .list ul li a {
  display: block;
  text-decoration: none;
  color: #000;
}
.full-content-round-select > .content-list > .list ul li:hover {
  background-color: #fafafa;
}
/*
.full-content-round-select > .list ul li a{
    position: relative;
    display: block;
    padding: 0 16px;
    line-height: 40px;
    color: #000;
    font-size: 15px;
    opacity: 1;
    font-weight: 400;
    background-color: #fff;
    text-decoration: none;
    cursor: pointer;
}
.full-content-round-select > .list ul li:hover a {
    background-color: #fafafa;
}*/
.full-content-round-select > .content-list > .list .separator {
  height: 1px;
  margin: 4px 0;
  padding: 0;
  background-color: #ebebeb;
}
/* End of file
----------------------------------------------------------------------------------------*/

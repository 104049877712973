#ProjectDetailView {
    position: relative;

    #background {
        width: 100vw;
        height: 112px;
        background-color: #f6f6f6cc;
        position: absolute;
        top: 0;
        z-index: -1;
    }
}

@media only screen and (max-width: 768px) {
    #mainItemScore {
        display: none;
    }
}
html {
  scroll-behavior: smooth;
}

#projectNewInsight {
  .full-content-list-opt-select.inDepthSelect .content .current-value::after {
    top: 5px;
    right: 0px;
    border: none;
    width: 20px;
    height: 20px;
    content: "";
    background: url("../../res/images/icons/expand-more-filled.svg");
    background-position: center center;
    background-size: cover;
    transform: rotate(0deg);
  }

  table {
    display: block;
    overflow-x: auto;
    max-width: 100%;
  }

  .central-content {
    margin-bottom: 40px;
    position: static;

    #main-content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-top: 34px;

      #menu {
        width: 20%;
        position: sticky;
        top: 98px;

        ul {
          li {
            padding: 12px 0;
            padding-left: 16px;
            border-radius: 6px 0 0 6px;
            cursor: pointer;

            a {
              font-size: 18px;
              font-weight: normal;
              color: #424242;
              text-decoration: none;
            }

            &:hover {
              background-color: #fafafa;
            }

            &.active {
              background-color: #f5f5f5;

              a {
                font-weight: 500;
              }
            }
          }
        }
      }

      #text {
        width: 80%;
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        border-left: 1px solid #eee;
        padding-left: 30px;
        font-size: 15px;
        color: #212121;

        a {
          font-weight: 500;
          text-decoration: none; /* Pour supprimer le soulignement par défaut */
          color: #3a7fe7; /* Couleur du lien */
          transition: border-bottom 0.3s; /* Transition pour ajouter le soulignement */
        }

        a:hover {
          border-bottom: 1px solid #3a7fe7; /* Ajouter le soulignement au survol */
        }

        h1 {
          font-size: 28px;
          font-weight: 600;
          color: #000;
          margin-bottom: 16px;
        }

        h2 {
          font-size: 22px;
          font-weight: 600;
          color: #000;
          margin-bottom: 14px;
        }

        h3 {
          font-size: 18px;
          font-weight: 600;
          color: #000;
          margin-bottom: 12px;
          margin-top: 12px;
        }

        h4 {
          font-size: 15px;
          font-weight: 600;
          color: #000;
          margin-bottom: 10px;
          margin-top: 10px;
        }

        ul {
          display: flex;
          flex-direction: column;
          row-gap: 6px;
          padding-left: 25px;
          padding-top: 15px;
          padding-bottom: 15px;

          li {
            position: relative;
            padding-left: 5px;
            list-style-type: disc;
          }
        }

        p {
          padding-bottom: 15px;
        }

        ol {
          counter-reset: orderedlist;
          display: flex;
          flex-direction: column;
          row-gap: 6px;
          padding-left: 25px;
          padding-top: 15px;
          padding-bottom: 15px;

          > li {
            position: relative;
            padding-left: 5px;
            list-style-type: inherit;

            &::before {
              counter-increment: orderedlist;
              content: counter(orderedlist);
              width: 15px;
              height: 15px;
              font-size: 10px;
              text-align: center;
              border: 1px solid black;
              border-radius: 100%;
              position: absolute;
              left: -20px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          // Styles pour les sous-listes
          ul {
            padding-left: 20px; // Indentation pour les sous-listes

            li {
              list-style-type: disc; // Utilise des puces pour les sous-éléments
              position: relative;
              padding-left: 5px;
            }
          }
        }

        /* Styles pour les citations */
        blockquote {
          margin: 0;
          padding: 10px;
          background-color: #f5f5f5;
          border-left: 3px solid #0077cc;
        }

        /* Styles pour les tableaux */
        table {
          margin-top: 15px;
          margin-bottom: 15px;
          border-collapse: collapse;
          width: fit-content;
        }

        table,
        th,
        td {
          border: 1px solid #343434;
        }

        th {
          background-color: #ededed;
        }

        th,
        td {
          padding: 10px;
          text-align: left;
        }

        /* Styles pour les notes de bas de page ou avertissements */
        .note {
          font-style: italic;
          color: #888;
        }

        /* Styles pour les éléments préformatés (balise pre) */
        pre {
          background-color: #f5f5f5;
          padding: 10px;
          font-family: Menlo, Monaco, monospace;
          white-space: pre-wrap;
        }

        /* Styles pour les éléments de liste (balise dl) */
        dl {
          margin: 10px 0;
        }

        /* Styles pour les définitions dans la liste (balise dt) */
        dt {
          font-weight: bold;
        }

        /* Styles pour les éléments de description dans la liste (balise dd) */
        dd {
          margin-left: 20px;
        }

        #lastUpdate {
          align-self: flex-end;
          font-size: 12px;
          color: #9e9e9e;
        }

        div {
          margin-bottom: 10px;
        }
      }
    }
  }

  #fond {
    width: 100vw;
    height: calc(100vh - 60px);
    position: fixed;
    top: 60px;
    left: 0;
    background-color: #0e004b4d;
    z-index: 19;
  }

  .share-tooltip {
    padding: 26px;
    width: 50vw;
    min-width: 300px;
    max-width: 560px;
    border-radius: 12px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    position: fixed;
    z-index: 20;
    left: 50%;
    top: calc((100vh / 2));
    transform: translate(-50%, -50%);

    h3 {
      font-size: 24px;
      font-weight: bold;
      color: #424242;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      color: #757575;
      display: flex;
      align-items: center;
      column-gap: 8px;
      margin-top: 26px;
      margin-bottom: 28px;
    }

    a {
      text-decoration: none;
    }

    .close {
      position: absolute;
      top: 18px;
      right: 18px;

      &:hover {
        cursor: pointer;
      }
    }

    .actions {
      font-size: 15px;
      font-weight: 500;
      color: #6d9eee;
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    .btn-box {
      display: flex;
      justify-content: space-between;

      span:hover {
        cursor: pointer;
      }

      .content {
        display: flex;
        align-items: center;
        column-gap: 28px;
        width: 100%;
      }

      .preview {
        display: flex;
        align-items: center;
        column-gap: 10px;
      }

      .button {
        justify-self: flex-end;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    #menu {
      display: none;
    }

    #text {
      width: 100% !important;
      border: none !important;
      padding: 0 !important;
    }
  }

  @media only screen and (max-width: 768px) {
    #insights-score {
      display: none;
    }
  }
}

#long-menu {
  .MuiPaper-root {
    margin-top: 0px;
    box-shadow: none !important;
    border: none;
    background-color: transparent !important;
    position: absolute;
    overflow: visible !important;

    & .MuiMenuItem-root {
      padding-left: 6px;
      padding-right: 6px;
      position: relative;
      left: 2px;
      bottom: 10px;
      overflow: visible !important;

      &:hover {
        background-color: transparent;
        position: relative;

        &::before {
          content: "";
          width: 35px;
          height: 35px;
          background-color: #0000000a;
          position: absolute;
          right: 1.5px !important;
          z-index: 0;
          border-radius: 100%;
        }
      }
    }
  }
}

#long-menu {
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters:hover {
    background-color: transparent;
    position: relative;

    &::before {
      content: "";
      width: 35px;
      height: 35px;
      background-color: #0000000a;
      position: absolute;
      right: 10.525px;
      z-index: 0;
      border-radius: 100%;
    }
  }
}

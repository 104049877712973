#radarGraphComponents {
  border-radius: 8px;
  border: solid 1px #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#radarGraphComponents #header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 15px;
}
#radarGraphComponents #header #title {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #9e9e9e;
}
#radarGraphComponents #header #title + div {
  width: 90px;
}
#radarGraphComponents #header #title + div div {
  height: 25px;
  padding: 0;
}
#radarGraphComponents #header #title + div label {
  display: none;
}
#radarGraphComponents #header #title + div *:not(svg) {
  color: #7f7f7f;
  border: none;
  font-size: 15px;
  font-weight: 500;
}
#radarGraphComponents #header #title + div svg {
  top: calc(50% - 0.55em);
}
#radarGraphComponents tspan {
  font-size: 12px;
  font-weight: 500;
  color: #757575;
}
#radarGraphComponents #box {
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: white;
}
#radarGraphComponents #box h3 + p {
  margin-top: 5px;
  margin-bottom: 15px;
}
#radarGraphComponents #box .col-box {
  display: flex;
  align-items: center;
}
#radarGraphComponents #box .col-box .col-box-item {
  width: 45%;
}
#radarGraphComponents #box .col-box h3 {
  font-size: 20px;
}
#radarGraphComponents #legend {
  margin-bottom: 16px;
  display: flex;
  -moz-column-gap: 15px;
       column-gap: 15px;
}
#radarGraphComponents #legend .legend-item {
  font-size: 12px;
  color: #9e9e9e;
  display: flex;
  align-items: center;
  -moz-column-gap: 6px;
       column-gap: 6px;
}
#radarGraphComponents #legend .legend-item #project {
  width: 7px;
  height: 7px;
  background-color: #3a79e6;
  border-radius: 100%;
}
#radarGraphComponents #legend .legend-item #industry {
  width: 12px;
  border: 1px dashed #f78f64;
}
#radarGraphComponents svg:hover {
  cursor: pointer;
}/*# sourceMappingURL=radarGraphComponents.css.map */
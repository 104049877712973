#noResult {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    align-items: center;
    margin-top: 24px;

    img {
        width: 250px;
    }

    h1 {
        font-family: 'Inter', sans-serif;
        font-size: 24px;
        font-weight: bold;
        color: #616161;
    }

    p {
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        line-height: 1.47;
        color: #757575;
    }
}
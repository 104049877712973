.filerItems span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#classTagBox {
  display: flex;
  align-items: center;
  column-gap: 9px;

  .tagTitle {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-align: left;
    color: #000;
  }

  .classTagBoxContent {
    width: 100%;
    display: flex;
    column-gap: 9px;

    .classtag {
      padding: 5px 10px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      border: solid 1px #616161;
      font-size: 13px;
    }
  }
}

#MostRelevantReviews {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 34px;
  margin-bottom: 16px;

  div:first-child {
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }

  div:last-child {
    font-size: 15px;
    font-weight: 500;
    color: #306ed6;
  }
}

#MostRelevantReviewsList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 18px;
  row-gap: 12px;
  max-width: 1100px;

  .MostRelevantReviewsListItem {
    max-width: 510px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 17px 15px;
    border-radius: 8px;
    border: solid 1px #eee;
    background-color: #fff;

    h3 {
      line-height: 23px;
      max-height: 69px;
      font-size: 18px;
      font-weight: normal;
      width: 100%;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      white-space: pre-wrap;
    }

    p {
      font-size: 10px;
      font-weight: 500;
      color: #9e9e9e;

      a {
        color: #3a79e6;
        text-decoration: none;
      }
    }
  }
}

.updateMessage {
  font-size: 18px;
  font-weight: 600;
  color: #9e9e9e;
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-top: 20px;

  @keyframes rotateImg {
    from {
      transform: rotate(0);
    }

    to {
      transform: rotate(-360deg);
    }
  }

  .rotate-img {
    animation: 2000ms rotateImg infinite linear;
  }
}

.graphLoad {
  width: 542px;
  height: 372px;
  background-color: #f5f5f5;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &:before {
    content: "";
    width: 70px;
    height: 150%;
    background-color: white;
    opacity: 0.4;
    position: absolute;
    right: 20%;
    top: 50%;
    transform: rotate(3deg) translateY(-50%);
  }

  &:after {
    content: "";
    width: 70px;
    height: 150%;
    background-color: white;
    opacity: 0.4;
    position: absolute;
    right: 40%;
    top: 50%;
    transform: rotate(3deg) translateY(-50%);
  }
}

#hide-inDepth {
  position: relative;
  filter: blur(5px);

  #hide-inDepth-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
}

@media screen and (max-width: 1114px) {
  #topGraph,
  #bottomGraphInDepth {
    flex-direction: column;
    row-gap: 20px;
    align-items: center !important;
  }

  #MostRelevantReviewsList {
    grid-template-columns: repeat(1, 1fr);

    .MostRelevantReviewsListItem {
      width: calc(100% - 30px);
      max-width: calc(100% - 30px);
    }
  }
}

@media screen and (max-width: 550px) {
  .chart,
  .full-content-list-opt-select,
  #topGraph,
  #bottomGraphInDepth {
    display: none !important;
  }
}

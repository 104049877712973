.pricing-showcase {
  position: relative;
  padding-bottom: 70px;
}

.pricing-showcase .content-pricing-box {
  width: 350px;
  display: inline-block;
  text-align: left;
  position: relative;
  padding: 24px 20px 84px 20px;
  border-radius: 8px;
  border: solid 1px #d2d2d2;
  background-color: #fff;
  box-sizing: border-box;
  height: 840px;
  border-top: 6px solid #f78f64;
}

.pricing-showcase .container-pricing-box .column-percent-34,
.pricing-showcase .container-pricing-box .column-percent-50 {
  text-align: center;
}

.pricing-showcase .container-pricing-box .column-percent-50:first-child {
  padding-right: 10px;
}
.pricing-showcase .container-pricing-box .column-percent-50:last-child {
  padding-left: 10px;
}
.pricing-showcase
  .container-pricing-box
  .column-percent-50
  .content-pricing-box,
.pricing-showcase
  .container-pricing-box
  .column-percent-100
  .content-pricing-box {
  width: 100%;
}

@media screen and (min-width: 1186px) {
  .container-pricing-box
    .container-pricing-box
    ercent-33:nth-child(2)
    .content-pricing-box {
    margin-left: 7px;
  }

  .pricing-showcase .container-pricing-box .column-percent-33:last-child {
    text-align: right;
  }
}

.pricing-showcase .content-pricing-box.recommended {
  border: solid 1px #f78f64;
}

.pricing-showcase .content-pricing-box:not(.recommended) .tag-recommended {
  display: none;
}

.pricing-showcase .content-pricing-box .tag-recommended {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
}

.pricing-showcase .content-pricing-box .tag-recommended div {
  padding: 1px 8px;
  border-radius: 3px;
  background-color: #f78f64;
  display: inline-block;
}

.pricing-showcase .content-text .title {
  color: #000;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pricing-showcase .content-text .subtitle {
  color: #7f7f7f;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.pricing-showcase .content-text .price {
  text-align: center;
}

.pricing-showcase .content-text .price .maj {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pricing-showcase .content-text .price .min {
  color: #000;
  text-align: center;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.pricing-showcase .content-text .sub-price {
  text-align: center;
  color: #7f7f7f;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

.pricing-showcase .content-text .sub-price.only-text {
  padding-top: 16px;
  line-height: 1.31;
  letter-spacing: -0.1px;
  padding-bottom: 30px;
}

.pricing-showcase .content-text .content-advantages .title {
  color: #000;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.17px;
  margin-bottom: 10px;
  text-align: start;
}

.pricing-showcase .content-text .content-advantages .raw-advantage {
  display: flex;
  align-items: center;
}

.pricing-showcase .content-text .content-advantages .advantages-box {
  box-sizing: border-box;
  min-height: 120px;
}

.pricing-showcase
  .content-text
  .content-advantages
  .advantages-box:first-child {
  height: 220px;
}

.pricing-showcase
  .content-text
  .content-advantages
  .advantages-box
  .advantage-lst {
  color: #7f7f7f;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}

.btn-position {
  position: absolute;
  bottom: 24px;
  left: 0;
  right: 0;
  text-align: center;
}

.pricing-showcase .button.backgroundless.contact-sales {
  width: 130px;
}

.pricing-showcase .button.light-blue {
  background-color: #e2ebfd;
  color: #3a79e6;
}

.pricing-showcase .button.backgroundless {
  background-color: transparent !important;
  color: #3a79e6;
  font-weight: 600;
  border-radius: 17px;
  border: solid 1px #3a79e6;
  width: 107px;
  font-size: 15px;
}

.button.backgroundless.font-weight-600 {
  font-weight: 600;
}

.orange-line {
  height: 6px;
  margin-top: -25px;
  margin-bottom: 24px;
  border-radius: 8px;
  background-color: #f78f64;
}

.plan-button {
  display: inline-block;
  width: 176px;
  height: 40px;
  margin: 204px 87px 0;
  padding: 10px 12px;
  border-radius: 6px;
  background-color: #e2ebfd;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: #3a79e6;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
}

.plan-button-business {
  display: inline-block;
  width: 176px;
  height: 40px;
  margin: 24px 27px 0;
  padding: 10px 12px;
  border-radius: 6px;
  border: solid 1px #979797;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  color: #2f2f2f;
  background-color: white;
  cursor: pointer;
  box-sizing: border-box;
  text-decoration: none;
}

.plan-button-upgrade {
  width: 176px;
  height: 40px;
  margin: 202px 27px 0;
  padding: 10px 12px;
  border-radius: 6px;
  background-color: #3a79e6;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.33;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.button.upgrade {
  width: 176px;
  height: 40px;
  z-index: 1;
  line-height: 40px;
}

.pricing-showcase .content-text .content-advantages {
  max-width: 235px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (max-width: 1147px) {
  .pricing-showcase .container-pricing-box .column-percent-33,
  .pricing-showcase .container-pricing-box .column-percent-34 {
    width: 50%;
  }
  .pricing-showcase
    .container-pricing-box
    .parent-content-pricing-box:nth-child(odd) {
    padding-right: 10px;
  }
  .pricing-showcase
    .container-pricing-box
    .parent-content-pricing-box:nth-child(even) {
    padding-left: 10px;
  }
  .pricing-showcase
    .container-pricing-box
    .column-percent-34
    .content-pricing-box,
  .pricing-showcase
    .container-pricing-box
    .column-percent-33
    .content-pricing-box {
    width: 100%;
    margin-bottom: 20px;
  }

  .btn-position {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .orange-line {
    height: 6px;
    margin-left: -36px;
    margin-top: -25px;
    margin-bottom: 24px;
    border-radius: 8px;
    background-color: #f78f64;
  }
}

@media screen and (max-width: 715px) {
  .pricing-showcase .container-pricing-box .column-percent-33,
  .pricing-showcase .container-pricing-box .column-percent-34,
  .pricing-showcase .container-pricing-box .column-percent-50 {
    width: 100%;
    display: block;
    float: none;
  }

  .pricing-showcase .container-pricing-box .parent-content-pricing-box {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .pricing-showcase .content-pricing-box {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    margin-left: 0;
  }

  .pricing-showcase .content-text .content-advantages .title {
    text-align: center;
  }

  .pricing-showcase .content-text .content-advantages {
    max-width: 215px;
    margin-left: auto;
    margin-right: auto;
  }

  .btn-position {
    position: absolute;
    bottom: 50px;
    left: 0;
    right: 0;
    text-align: center;
  }

  .yearly-save {
    margin-top: 10px;
  }
}

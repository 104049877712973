@keyframes animationFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animationFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.c-modal.c-modal__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  color: #000;
  font-size: 16px;
  background-color: rgba(14, 0, 75, 0.3);
  opacity: 1;
  animation: animationFadeIn 0.3s ease;
  z-index: 19 !important;
}

.c-modal.c-modal__overlay.c-modal__closed {
  opacity: 1;
  animation: animationFadeOut 0.3s ease;
  animation-fill-mode: forwards;
}

.c-modal-content-scroll {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-x: hidden;
  box-sizing: border-box;
}

.c-modal-content-table {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
  padding: 30px 0;
  box-sizing: border-box;
}
.c-modal.c-modal__overlay.fullsize .c-modal-content-table {
  padding: 0;
}

.c-modal-content-table-cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.c-modal .c-modal__modal {
  position: relative;
  overflow: hidden;
  text-align: left;
  left: 50%;
  transform: translateX(-50%);
  max-width: 640px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
}

.c-modal.fullsize .c-modal__modal{
  position: relative;
  overflow: hidden;
  text-align: left;
  left: 0;
  transform: none;
  max-width: 100%;
  min-height: 100vh;
  background-color: #fff;
  border-radius: 0;
  box-shadow: none;
}

.c-modal .c-modal__close {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  cursor: pointer;
  z-index: 3;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 44px 44px;
  background-image: url('../../res/images/pic-close-modal.svg');
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.c-modal.c-modal.fullsize .c-modal__close {
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  background-image: url('../../res/images/pic-close-modal-big.svg');
}

.c-modal .c-modal__close:hover {
  opacity: 0.8;
}

.c-modal .c-modal__content {
  position: relative;
  padding: 36px;
}
.c-modal.c-modal.fullsize .c-modal__content {
  padding-top: 20px;
}

.content-modal-helper-idea {
  position: relative;
}
.content-modal-helper-idea .icon {
  position: absolute;
  display: block;
  width: 10px;
  height: 17px;
  top: 5px;
  left: 10px;
  background-size: 10px 17px;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('../../res/images/pic-ampoule.svg');
}
.content-modal-helper-idea .text {
  position: relative;
  display: block;
  line-height: 1.47;
  margin-left: 40px;
}

.c-modal .c-modal__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  display: block;
}
@media screen and (max-width: 600px) {
  .c-modal.c-modal.fullsize .c-modal__content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .c-modal .c-modal__title {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin-top: 20px;
  }
}
.central-content .logo{
  display: inline-block;
  vertical-align: middle;
}

.modal-header {
  padding-top: 18px;
}

.grey-line {
  margin-top: 17px;
  border-top: 1px solid #ebebeb;
}

.color-e04f77 {
  color: #e04f77 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

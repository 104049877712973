/* .unconnected-content
----------------------------------------------------------------------------------------*/

.unconnected-content.double {
  position: relative;
  min-height: 100vh;
}
.unconnected-content.double,
.unconnected-content.double-percent-50 {
  display: flex;
}

.unconnected-content.double .content-blue {
  position: relative;
  flex: calc(100% - 590px);
  min-height: 100vh;
  background-color: #3a79e6;
}
.unconnected-content.double-percent-50 .content-blue {
  position: relative;
  flex: 40%;
  min-height: 100vh;
}
.unconnected-content .content-blue.background-color-fff {
  background-color: #fff;
}
.unconnected-content .content-blue .icon {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  background-image: url("../../res/images/illustrations/illus-signup.png");
}
.unconnected-content .content-blue .icon.login {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-image: url("../../res/images/illustrations/illus-login.png");
}
.unconnected-content .content-blue .icon.email {
  right: 20px;
  background-size: contain;
  background-position: center center;
  background-image: url("../../res/images/illustrations/illus-email.png");
}
.unconnected-content .content-blue .icon.pw-lock {
  right: 20px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center center;
  background-image: url("../../res/images/illustrations/illus-pw-lock.png");
}
.unconnected-content .content-blue .icon.width-percent-40 {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}

.unconnected-content .content-white {
  position: relative;
  max-width: 590px;
  min-height: 100vh;
  background-color: #fff;
}

.unconnected-content.double .content-white {
  flex: 590px;
}
.unconnected-content.double-percent-50 .content-white {
  flex: 50%;
  max-width: none;
}
.content-max-width-590 {
  position: relative;
  max-width: 590px;
}

.unconnected-content.central .content-white {
  margin: 0 auto;
}

.unconnected-content .content-white .c-content-table {
  position: relative;
  display: table;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding: 88px 110px 65px 110px;
  box-sizing: border-box;
}
.unconnected-content .content-white .c-content-table.padding-horizontal-80 {
  padding-left: 80px;
  padding-right: 80px;
}
.unconnected-content .content-white .c-content-table.padding-bottom-90 {
  padding-bottom: 90px;
}

.unconnected-content .content-white .c-content-table-cell {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.unconnected-content .content-white .centralized-content {
  position: relative;
  overflow: hidden;
  text-align: left;
  left: 50%;
  transform: translateX(-50%);
}

.footer-unconnected {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 110px 20px 110px;
  text-align: center;
}

@media only screen and (max-width: 980px) {
  .unconnected-content.double,
  .unconnected-content.double-percent-50 {
    display: block;
  }
  .unconnected-content.double .content-blue,
  .unconnected-content.double-percent-50 .content-blue {
    display: none;
    flex: none;
  }
  .unconnected-content.double .content-white,
  .unconnected-content.double-percent-50 .content-white {
    display: block;
    margin: 0 auto;
    flex: none;
  }
}

@media only screen and (max-width: 590px) {
  .unconnected-content .content-white .footer-unconnected,
  .unconnected-content .content-white .c-content-table {
    max-width: 430px;
    box-sizing: border-box;
    margin: 0 auto;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .unconnected-content
    .content-white
    .c-content-table
    .centralized-content.padding-horizontal-30 {
    padding-left: 0;
    padding-right: 0;
  }
}

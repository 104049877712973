.customlabel {
  position: relative;
}
.customlabel > div > div > div {
  min-height: 30px !important;
  margin: 0px 0px 0px 0;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  position: relative;
  z-index: 3;
}
.customlabel > div > div > div [class$=-Input] {
  height: 34px !important;
}
.customlabel::before {
  content: "Search for Keywords";
  position: absolute;
  height: 20px;
  top: -10px;
  left: 10px;
  background-color: white;
  padding: 0px 5px;
  color: #3a79e6;
  font-weight: 600;
  font-size: 15px;
  z-index: 2;
}/*# sourceMappingURL=style.css.map */
#carouselContent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 44px;

    .rotate-img {
        animation: 2000ms rotateImg infinite linear;
    }

    h3 {
        width: 90vw;
        height: 18px;
        font-family: 'Inter', sans-serif;
        font-size: 15px;
        font-weight: 600;
        letter-spacing: 0.15px;
        color: #9e9e9e;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3px;
    }

    h1 {
        font-family: 'Inter', sans-serif;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        color: #616161;
        position: absolute;
        width: 90vw;
        top: 77px;
    }

    #slideInfos {
        position: absolute;
        top: 382px;
        z-index: 10;

        #dots {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;

            .dot {
                width: 9px;
                height: 9px;
                flex-grow: 0;
                margin: 0 10px 0 0;
                border: solid 1px #a4a4a4;
                border-radius: 100px;
                transition: 200ms ease-in-out;

                &-selected {
                    background-color: #a4a4a4;
                    border: solid 1px #a4a4a4;
                }

                &:hover {
                    cursor: pointer;
                }
            }
        }

        h2 {
            margin-top: 28px;
            flex-grow: 0;
            font-family: 'Inter', sans-serif;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
        }

        p {
            margin-top: 10px;
            flex-grow: 0;
            font-family: 'Inter', sans-serif;
            font-size: 15px;
            line-height: 1.47;
            letter-spacing: normal;
            text-align: center;
            color: #7f7f7f;
            width: 90vw;
            max-width: 648px;
        }
    }
}
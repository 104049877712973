.card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 330px;
  height: -moz-fit-content;
  height: fit-content;
}
.card img {
  width: 100%;
}/*# sourceMappingURL=Card.css.map */
#test-zone {
    height: calc(100vh - 60px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    h1 {
        width: 95%;
        text-align: left;
        font-size: 25px;
        text-transform: uppercase;
        color: red;
    }

    #test-area {
        width: 95%;
        height: 90%;
        display: flex;
        column-gap: 20px;
        align-items: flex-start;
    }
}
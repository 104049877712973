.admin-view .MuiTable-root {
  border-collapse: separate;
}

.admin-view .MuiTableCell-root.MuiTableCell-head {
  top: 60px;
}

.admin-view .admin-custom-layout {
  min-height: 0;
}

.admin-view .admin-custom-layout > div:last-child {
  margin-top: 10px;
}

.admin-view .admin-custom-layout div:last-child > main > div:first-child {
  height: calc(100vh - 70px);
}

.admin-view .field-cell-text-ellipsis {
  overflow: hidden;
  max-width: 20em;
  white-space: nowrap;
  text-overflow: ellipsis;
}

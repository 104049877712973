#textareaTitle {
    position: absolute;
    height: 22px;
    top: -10px;
    left: 16px;
    padding: 0px 5px;
    z-index: 3;
    background-color: white;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    color: #000;
}
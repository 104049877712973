/* List
----------------------------------------------------------------------------------------*/
.content-list-items {
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
  font-size: 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 22px;
}

.content-list-items .project-list-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 352px;
  height: 335px;
  padding: 0 0 10px;
  border-radius: 6px;
  border: solid 1px #eee;
  background-color: #fff;
  box-sizing: border-box;
}

.content-list-items .mini-project-list-item:not(.archived):not(.locked),
.content-list-items .project-list-item:not(.archived):not(.locked) {
  cursor: pointer;
}

.mini-project-list-item.archived,
.content-list-items .project-list-item.archived .archive-opacity {
  opacity: 0.4;
}

.mini-project-list-item.locked,
.content-list-items .project-list-item.locked .archive-opacity {
  opacity: 0.4;
}

.banner-locked-project {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 6px 6px 6px 28px;
  border-radius: 6px 6px 0 0;
  background-color: #b4b4b4;
  box-sizing: border-box;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  background-image: url("../../res/images/plan-icons/lock-key.svg");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: 8px center;
}

.mini-project-list-item .banner-locked-project {
  border-radius: 0;
}

.mini-project-list-item:not(.archived):not(.locked):hover,
.content-list-items .project-list-item:not(.archived):not(.locked):hover {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.2);
  border: solid 1px #eee;
}

@media only screen and (min-width: 1141px) {
  .content-list-items {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 1140px) and (min-width: 751px) {
  .content-list-items {
    max-width: 720px;
  }
  .content-list-items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 750px) {
  .content-list-items {
    max-width: 340px;
  }
}
@media only screen and (max-width: 370px) {
  .content-list-items .project-list-item {
    display: block;
    width: auto;
  }
}
.content-list-items .project-list-item .full-content-round-select {
  z-index: 3;
}

.content-list-items .project-list-item .picture {
  position: relative;
  display: block;
  height: 151px;
  margin: 10px 5px 0;
  border-radius: 6px 6px 0 0;
  background-image: url("../../res/images/placeholder-img.svg");
}

.content-list-items .project-list-item .content-time {
  position: absolute;
  right: 16px;
  bottom: 10px;
}

.content-list-items .project-list-item .content-round-select {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

/* .mini-project-list-item
  ----------------------------------------------------------------------------------------*/
.mini-project-list-item {
  position: relative;
  display: block;
  height: 80px;
  padding: 0;
  border-radius: 6px;
  border: solid 1px #d2d2d2;
  background-color: #fafafa;
  box-sizing: border-box;
  margin-bottom: 10px;
  overflow: hidden;
}

.mini-project-list-item .picture {
  position: absolute;
  width: 125px;
  height: 80px;
  top: 0;
  left: 0;
  background-image: url("../../res/images/placeholder-img.svg");
}

.mini-project-list-item .content-left {
  position: relative;
  margin-left: 125px;
  padding: 10px 12px;
}

/* .media-list-item
  ----------------------------------------------------------------------------------------*/
.media-list-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 165px;
  border-radius: 6px;
  border: solid 1px #eee;
  background-color: #fff;
  box-sizing: border-box;
  margin-bottom: 8px;
  -moz-column-gap: 24px;
  column-gap: 24px;
}

.media-list-item.success:hover {
  cursor: pointer;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.media-list-item.inprogress:hover,
.media-list-item.failed:hover,
.media-list-item.toprocess:hover {
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 3px 3px -2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.media-list-item.success:hover .hoverInfo,
.media-list-item.inprogress:hover .hoverInfo,
.media-list-item.toprocess:hover .hoverInfo,
.media-list-item.failed:hover .hoverInfo {
  opacity: 1;
  z-index: 3;
}

.media-list-item .picture {
  width: 254px;
  height: 100%;
  border-radius: 2px;
  background-image: url("../../res/images/placeholder-img.svg");
}

.media-list-item .picture .content-duration {
  position: absolute;
  bottom: 5px;
  right: 5px;
  border-radius: 2px;
  background-color: rgba(50, 50, 50, 0.86);
  padding: 2px;
}

.media-list-item .content-left {
  position: relative;
  height: calc(100% - 20px);
  width: calc(100% - 125px);
  display: flex;
  flex-direction: column;
}

.media-list-item .content-captions-infos {
  min-width: 150px;
  margin-bottom: -15px;
}

.media-list-item .content-captions-infos.failed {
  display: flex;
  align-items: center;
  gap: 6px;
}

.media-list-item .round-emotion-color {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: #b6b2c9;
  margin-right: 6px;
}

.media-list-item .content-left .content-status span {
  white-space: nowrap;
}

.media-status {
  display: flex;
}

.media-list-item .content-left .content-status .icon-media-status,
.only-responsive-920 .icon-media-status {
  width: 24px;
  height: 24px;
}

.media-list-item .content-left .content-status .icon-media-status.to-process,
.media-list-item .content-left .content-status .icon-media-status.in-progress,
.only-responsive-920 .icon-media-status.in-progress {
  background-image: url("../../res/images/media-status/cached-filled.svg");
  background-size: contain;
  transform: rotateY(180deg);
}

.media-list-item .content-left .content-status .icon-media-status.in-progress {
  background-image: url("../../res/images/media-status/cached-filled.svg");
  background-size: contain;
  transform: rotateY(180deg);
}

.only-responsive-920 .icon-media-status.failed,
.media-list-item .content-left .content-status .icon-media-status.failed {
  background-image: url("../../res/images/media-status/pic-analyse-nok.svg");
  background-repeat: no-repeat;
}

.media-list-item .content-left .content-status .icon-media-status.success {
  background-image: url("../../res/images/media-status/pic-analyse-ok.svg");
}

.media-status.failed {
  width: 120px;
}

.media-status.in-progress {
  align-items: center;
}

.media-status.failed .text {
  width: calc(100% - 25px);
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.media-list-item .link-absolute {
  z-index: auto;
}

.media-list-item .content-buttons {
  position: absolute;
  top: 30px;
  right: 10px;
}

.media-list-item .content-buttons .content-round-select {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  opacity: 0;
}

.media-list-item:hover .content-buttons .hoverInfo {
  opacity: 1;
}

.media-list-item .content-buttons .loader-button,
.media-list-item .content-buttons button,
.media-list-item .content-buttons .left-button,
.media-list-item .content-buttons .full-content-round-select {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.media-list-item .content-buttons .left-button {
  width: 150px;
}

.media-list-item .content-buttons .full-content-round-select {
  top: 2px;
  margin-left: 10px;
}

@media screen and (max-width: 1120px) {
  .media-list-item .content-left .column-score {
    display: none !important;
  }
}
@media screen and (max-width: 1120px) {
  .no-responsive-1120 {
    display: none !important;
  }
}
@media screen and (max-width: 920px) {
  .media-list-item .content-left .column-title {
    width: auto !important;
  }
  .media-list-item .content-left .column-score {
    display: none !important;
  }
  .media-list-item .content-buttons .left-button {
    width: 100px;
  }
  .media-list-item .content-left .column-button .emotion {
    display: none !important;
  }
  .content-captions-infos-responsive {
    display: flex;
    flex-direction: row;
    padding-bottom: 15px;
  }
  .padding-top-0-responsive {
    padding-top: 0 !important;
  }
  .padding-left-5-responsive {
    padding-left: 5px;
  }
}
@media screen and (max-width: 768px) {
  /*
    .media-list-item .picture{
        display: none;
    }
    .media-list-item .content-left {
        margin-left: 0;
    }*/
  .media-list-item .content-left .hide-768 {
    display: none !important;
  }
  .media-list-item .content-left .column-status {
    width: 50px !important;
  }
  .media-list-item .content-left .column-button {
    width: 80px !important;
  }
}
/* .workspace-list-item
  ----------------------------------------------------------------------------------------*/
.workspace-list-item {
  position: relative;
  display: block;
  padding: 0;
  margin-bottom: 16px;
  margin-left: -20px;
}

.workspace-list-item table {
  position: relative;
  width: 100%;
  height: 100%;
}

.workspace-list-item table td,
.workspace-list-item table td * {
  vertical-align: top;
}

.workspace-list-item .workspace-content-to-hover {
  position: relative;
  padding-left: 10px;
}

.workspace-list-item .workspace-content-to-hover .workspace-content-hover {
  padding: 8px 10px;
}

.workspace-list-item .workspace-content-to-hover:hover .workspace-content-hover {
  background-color: #fafafa;
}

.workspace-list-item .workspace-loading {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  padding: 0 5px 0 12px;
  border-radius: 6px;
  border: solid 2px #3a79e6;
  background-color: #ffffff;
  text-align: center;
  box-sizing: border-box;
}

.workspace-list-item .workspace-loading .website-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
}

.workspace-list-item .workspace-scroll-content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  padding: 0 5px 0 12px;
  border-radius: 6px;
  border: solid 2px #3a79e6;
  background-color: #ffffff;
  text-align: center;
  box-sizing: border-box;
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
}

.workspace-list-item .workspace-scroll-content .content {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px;
  overflow-x: hidden;
}

.workspace-list-item .workspace-scroll-content .content .caption {
  position: relative;
  margin-bottom: 8px;
  padding: 8px 12px;
  border-radius: 6px;
  text-align: left;
}

.workspace-list-item .workspace-scroll-content .content .caption.selected {
  background-color: rgba(58, 121, 230, 0.1);
}

.workspace-list-item .workspace-scroll-content .masque {
  position: absolute;
  top: 0;
  left: 10px;
  right: 10px;
  height: 24px;
  background-image: linear-gradient(to bottom, #ffffff, rgba(255, 255, 255, 0));
}

.workspace-list-item .workspace-scroll-content .icon {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  right: -10px;
  z-index: 17;
  top: 50%;
  margin-top: -10px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../../res/images/workspace/scroll-icon.svg");
  box-sizing: border-box;
}

@media screen and (max-width: 1140px) {
  .workspace-list-item {
    margin-left: 0;
  }
  .workspace-list-item .workspace-content-to-hover {
    padding-left: 0;
  }
}
@media screen and (max-width: 1035px) {
  .workspace-list-item .column-emotion {
    display: none;
  }
}
@media screen and (max-width: 880px) {
  .workspace-list-item .column-score {
    display: none;
  }
  .workspace-list-item .column-main {
    width: auto !important;
    padding-right: 0;
  }
}
/* .settings-user-list-item
  ----------------------------------------------------------------------------------------*/
.settings-user-list-item {
  position: relative;
  display: block;
  height: 72px;
  padding: 0;
  border-radius: 6px;
  border: solid 1px #fff;
  background-color: #fff;
  box-sizing: border-box;
  margin-bottom: 5px;
}

.settings-user-list-item:hover {
  border: solid 1px #3a79e6;
  background-color: #fff;
}

.settings-user-list-item .sensia-table {
  height: 72px;
}

.colored-round-user {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 16px;
  background-image: linear-gradient(to bottom, #e04f77, #f78f64);
  color: #fff;
  text-align: center;
  font-size: 16px;
  text-transform: capitalize;
}

.colored-round-user.pictured {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  font-size: 0;
}

.colored-round-user.background-0 {
  background-image: linear-gradient(to bottom, #e04f77, #f78f64) !important;
}

.colored-round-user.background-1 {
  background-image: linear-gradient(to bottom, #3a79e6, #30beec) !important;
}

.colored-round-user.background-2 {
  background-image: linear-gradient(to bottom, #924ac4, #3a79e6) !important;
}

.colored-round-user.background-3 {
  background-image: linear-gradient(to bottom, #f78f64, #ffc933) !important;
}

.colored-round-user.background-4 {
  background-image: linear-gradient(to bottom, #30beec, #4bd69d) !important;
}

.colored-round-user.background-other {
  background-color: #564c81;
  background-image: none;
}

.content-stacked-colored-round-user {
  position: relative;
  display: inline-block;
  text-align: right;
}

.content-stacked-colored-round-user .colored-round-user {
  float: right;
}

.content-stacked-colored-round-user .colored-round-user:not(:last-child) {
  margin-left: -12px;
}

.content-stacked-colored-round-user .colored-round-user.background-other {
  margin-left: -6px;
}

.round-pending {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffbb00;
}

.settings-user-list-item .full-content-round-select {
  opacity: 0;
}

.settings-user-list-item:hover .full-content-round-select {
  opacity: 1;
}

.settings-user-list-item .full-content-round-select > .content-list > .list {
  width: 186px;
  margin-left: -93px;
}

/* .settings-payment-methods
  ----------------------------------------------------------------------------------------*/
@media screen and (max-width: 900px) {
  .content-columns-payment-method .column-percent-60 .padding-right-30 {
    padding-right: 0;
  }
}
.settings-payment-methods-list-item {
  position: relative;
  display: block;
  padding: 0;
  margin-bottom: 5px;
}

.settings-payment-methods-list-item .remove-card-div {
  position: absolute;
  top: 3px;
  right: 0;
  width: 24px;
  height: 24px;
  border: none;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  background-image: url("../../res/images/icons/pic-close.svg");
}

.settings-payment-methods-list-item:hover .remove-card-div {
  cursor: pointer;
  opacity: 1;
}

.settings-payment-methods-list-item .icon-card-type {
  position: absolute;
  top: 3px;
  left: 0;
  width: 26px;
  height: 16px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.settings-payment-methods-list-item .icon-card-type.card-icon {
  background-image: url("../../res/images/icons/bt-card.svg");
}

.settings-payment-methods-list-item .icon-card-type.bank-icon {
  background-image: url("../../res/images/icons/bt-bank.svg");
}

.settings-payment-methods-list-item .content-table {
  padding-left: 34px;
}

.settings-payment-methods-list-item .choose-as-default-button {
  opacity: 0;
}

.settings-payment-methods-list-item:hover .choose-as-default-button {
  opacity: 1;
}

.settings-payment-methods-list-item-divider {
  position: relative;
  margin: 14px 0;
  height: 1px;
  background-color: #ebebeb;
}

.deactivate_user {
  font-size: 13px;
  font-weight: normal;
  color: var(--black);
}

/* model modal settings list
  ----------------------------------------------------------------------------------------*/
.white-space-normal {
  white-space: normal;
}

.title-modale {
  position: absolute;
  top: 60px;
  margin-top: 3px;
  width: 280px;
  padding: 12px 16px;
  border-radius: 6px;
  border: solid 1px #b4b4b4;
  background-color: #fff;
  box-sizing: border-box;
  word-break: break-word;
  z-index: 17;
}

.width-100-px {
  width: 100px;
}

.width-150-px {
  width: 150px;
}

.height-40-px {
  height: 40px;
}

.height-50-px {
  height: 50px;
}

.webkit-text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* project top emotion
  ----------------------------------------------------------------------------------------*/
.round-emotion-color-project {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  margin-right: 5px;
}

.line-height-37 {
  line-height: 37px;
}

/* width
  ----------------------------------------------------------------------------------------*/
.width-percent-100 {
  width: 100%;
}

.width-percent-92 {
  width: 92%;
}

.width-percent-80 {
  width: 80%;
}

/* new css 
  ----------------------------------------------------------------------------------------*/
.test-type {
  display: flex;
  align-items: center;
  -moz-column-gap: 4px;
       column-gap: 4px;
}
.test-type img {
  width: 16px;
  height: 16px;
}

.mediaList {
  display: flex;
  -moz-column-gap: 8px;
  gap: 8px;
  min-height: 28px;
  position: relative;
}
.mediaList:hover {
  cursor: pointer;
}

.mediaList .mediaItem {
  width: auto;
  height: 24px;
  display: flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 3px;
  background-color: #f5f5f5;
  font-size: 12px;
  font-weight: 500;
  color: #424242;
  -moz-column-gap: 4px;
  column-gap: 4px;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 16px;
  padding-right: 3px;
  min-height: 66px;
}

.bottom #scoreAndReviews {
  height: 44px;
  display: flex;
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.bottom #scoreAndReviews .separator {
  width: 1px;
  height: 100%;
  background-color: #eee;
  margin-left: 16px;
}

.bottom #scoreAndReviews .number-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 4px;
  position: relative;
}

.bottom #scoreAndReviews .number-box p {
  font-size: 10px;
  font-weight: 500;
  color: #bdbdbd;
  text-transform: uppercase;
}

.bottom #scoreAndReviews .number-box h3 {
  height: 24px;
  font-size: 20px;
  font-weight: 500;
  color: #000;
  display: flex;
  align-items: flex-end;
  -moz-column-gap: 5px;
  column-gap: 5px;
}

.bottom .timeAndSelect {
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
  column-gap: 8px;
}

.bottom .timeAndSelect .roundSelect * {
  background-color: transparent;
}

.content-media-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.content-media-list .my-left {
  width: 100%;
}

.my-right {
  display: flex;
  -moz-column-gap: 15px;
  flex-wrap: nowrap;
  column-gap: 15px;
  align-items: flex-start;
  margin-top: 4px;
}

.my-right button {
  font-size: 15px;
  font-weight: 500;
  color: #306ed6;
  position: relative;
  bottom: 2px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
}
.my-right button:hover {
  cursor: pointer;
}

.my-right .roundSelect {
  position: relative;
  bottom: 7px;
}

.itemScore {
  align-self: flex-end;
}

.hoverInfo {
  display: flex;
  gap: 10px;
  opacity: 0;
  margin-right: 6px;
}

#hoverInfoTime {
  white-space: nowrap;
}

@media only screen and (max-width: 830px) {
  #itemScore {
    display: none;
  }
  #insights-score {
    display: none;
  }
}
@media only screen and (max-width: 685px) {
  .media-list-item .picture {
    display: none;
  }
  #medialistitem {
    margin-right: 10px;
  }
  .mediaList {
    flex-wrap: wrap;
  }
  .content-media-list {
    display: block;
  }
  .my-right {
    margin-top: 14px;
    -moz-column-gap: 0;
         column-gap: 0;
    flex-wrap: wrap;
    row-gap: 14px;
  }
}
/* End of file
----------------------------------------------------------------------------------------*//*# sourceMappingURL=list.css.map */
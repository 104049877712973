#LineAreaGraphComponents {
  border-radius: 8px;
  border: solid 1px #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
#LineAreaGraphComponents #fond {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  top: 0;
  left: 0;
  background-color: #f5f5f5;
}
#LineAreaGraphComponents #fond:before {
  content: "";
  width: 70px;
  height: 150%;
  background-color: white;
  opacity: 0.4;
  position: absolute;
  right: 20%;
  top: 50%;
  transform: rotate(3deg) translateY(-50%);
}
#LineAreaGraphComponents #fond:after {
  content: "";
  width: 70px;
  height: 150%;
  background-color: white;
  opacity: 0.4;
  position: absolute;
  right: 40%;
  top: 50%;
  transform: rotate(3deg) translateY(-50%);
}
#LineAreaGraphComponents #header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 15px;
}
#LineAreaGraphComponents #header [role=button] {
  font-size: 15px !important;
  font-weight: 500;
}
#LineAreaGraphComponents #header #title {
  font-weight: 500;
  text-transform: uppercase;
  color: #9e9e9e;
  font-size: 12px;
}
#LineAreaGraphComponents #header #title + div {
  position: relative;
  width: 120px;
}
#LineAreaGraphComponents #header #title + div div {
  height: 25px;
  padding: 0;
}
#LineAreaGraphComponents #header #title + div label {
  display: none;
}
#LineAreaGraphComponents #header #title + div #demo-simple-select {
  position: absolute;
  right: -5px;
}
#LineAreaGraphComponents #header #title + div *:not(svg) {
  color: #7f7f7f;
  border: none;
  font-size: 15px;
  font-weight: 500;
}
#LineAreaGraphComponents #header #title + div svg {
  top: calc(50% - 0.55em);
}
#LineAreaGraphComponents #box {
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: white;
}
#LineAreaGraphComponents #box h3 + p {
  margin-top: 5px;
}
#LineAreaGraphComponents #box .col-box {
  display: flex;
  align-items: center;
}
#LineAreaGraphComponents #box .col-box .col-box-item {
  width: 45%;
}
#LineAreaGraphComponents #box .col-box h3 {
  font-size: 20px;
}
#LineAreaGraphComponents #legend {
  margin-bottom: 16px;
  display: flex;
  -moz-column-gap: 15px;
       column-gap: 15px;
}
#LineAreaGraphComponents #legend .legend-item {
  font-size: 12px;
  color: #9e9e9e;
  display: flex;
  align-items: center;
  -moz-column-gap: 6px;
       column-gap: 6px;
}
#LineAreaGraphComponents #legend .legend-item #project {
  width: 7px;
  height: 7px;
  background-color: #27c985;
  border-radius: 100%;
}
#LineAreaGraphComponents #legend .legend-item #industry {
  width: 12px;
  border: 1px dashed #f78f64;
}
#LineAreaGraphComponents svg:hover {
  cursor: pointer;
}/*# sourceMappingURL=lineAreaGraphComponents.css.map */
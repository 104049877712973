/* List opt Select
----------------------------------------------------------------------------------------*/
.full-content-list-opt-select {
  position: relative;
  display: inline-block;
}

.full-content-list-opt-select .content {
  position: relative;
  display: inline-block;
  height: 32px;
  line-height: 30px;
  border-radius: 6px;
  padding: 0 26px 0 8px;
  border: solid 1px transparent;
  box-sizing: border-box;
  color: #969696;
  font-size: 15px;
  cursor: pointer;
}

.full-content-list-opt-select.big-blue .content {
  height: 30px;
  border-radius: 0;
  padding: 0 26px 0 0;
  border: none;
  box-sizing: border-box;
  color: #3a79e6;
  font-size: 24px;
  font-weight: bold;
}

.full-content-list-opt-select.big-blue.black .content {
  color: #000;
  font-size: 28px;
}

.full-content-list-opt-select.little-white .content {
  height: 30px;
  border-radius: 0;
  padding: 0 26px 0 0;
  border: none;
  box-sizing: border-box;
  color: #f2f2f2;
  font-size: 13px;
}

.full-content-list-opt-select:not(.big-blue):not(.little-white):not(.inDepthSelect).opened .content .current-value::after {
  transform: translateY(-50%) rotate(0);
}

.full-content-list-opt-select:not(.little-white) .content .current-value::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
  width: 20px;
  height: 20px;
  background-image: url(../../res/images/icons/right-action.svg);
  background-position: center;
}

.full-content-list-opt-select:not(.little-white).classSelector .content .current-value::after {
  content: "";
  position: absolute;
  top: 51%;
  transform: translateY(-50%) rotate(180deg);
  width: 24px;
  height: 24px;
  background-image: url(../../res/images/icons/right-action.svg);
  background-position: center;
  background-size: 200%;
  margin-left: 10px;
}

.full-content-list-opt-select:not(.little-white) .content .current-value.color-3a79e6::after {
  content: "";
  width: 20px;
  height: 20px;
  background-image: url(../../res/images/icons/icon-right.svg);
}

.full-content-list-opt-select.little-white .content .current-value::after {
  content: url("../../res/images/icons/bt-select-white.svg");
  position: relative;
  margin-left: 8px;
  width: 5px;
}

.full-content-list-opt-select.big-blue .content .current-value::after {
  top: 12px;
  right: 4px;
  border-width: 9px 7px 0 7px;
  border-color: #d8d8d8 transparent transparent transparent;
}

.full-content-list-opt-select > .list {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  min-width: 280px;
  width: 100%;
  padding: 5px 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  background-color: #fff;
  z-index: 15;
  border-radius: 6px;
  margin-top: 4px;
}
.full-content-list-opt-select > .list.sort-list {
  min-width: 235px;
}

.full-content-list-opt-select.big-blue > .list {
  width: auto;
  max-width: calc(100vw - 32px);
  padding: 4px;
  box-sizing: border-box;
}

.full-content-list-opt-select.little-white > .list {
  width: auto;
  max-width: calc(100vw - 32px);
  padding: 4px 0;
  box-sizing: border-box;
}

.full-content-list-opt-select.position-right > .list {
  right: 0;
}

.full-content-list-opt-select.opened > .list {
  opacity: 1;
  visibility: visible;
}

.full-content-list-opt-select > .list ul {
  position: relative;
  list-style-type: none;
}

.full-content-list-opt-select > .list ul li {
  position: relative;
  display: block;
  padding: 0 36px 0 16px;
  line-height: 40px;
  color: #000;
  font-size: 15px;
  opacity: 1;
  font-weight: 400;
  background-color: #fff;
  cursor: pointer;
}

.full-content-list-opt-select.big-blue > .list ul li:not(:last-child) {
  margin-bottom: 2px;
}

.full-content-list-opt-select > .list ul li.message {
  cursor: default;
}

.full-content-list-opt-select.big-blue > .list ul li:not(.message) {
  font-weight: 600;
}

.full-content-list-opt-select.little-white > .list ul li:not(.message) {
  color: #7f7f7f;
}

.full-content-list-opt-select.big-blue > .list ul li.message {
  font-weight: 400;
  font-size: 14;
  color: #7f7f7f;
}

.full-content-list-opt-select:not(.big-blue):not(.little-white) > .list ul li:not(.message).active {
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
  background-size: 18px 18px;
  background-image: url("../../res/images/pic-chekbox-small-on.svg");
}

.full-content-list-opt-select > .list ul li:not(.message):hover {
  background-color: #fafafa;
}

.full-content-list-opt-select.big-blue > .list ul li:not(.message):hover {
  background-color: rgba(58, 121, 230, 0.1);
}

.full-content-list-opt-select.big-blue > .list ul li:not(.message).active {
  background-color: rgba(58, 121, 230, 0.2);
}

.full-content-list-opt-select.little-white > .list ul li:not(.message):hover {
  background-color: #fafafa;
}

.full-content-list-opt-select.little-white > .list ul li:not(.message).active {
  background-color: #e9e9e9;
}

.full-content-list-opt-select > .list .separator {
  height: 1px;
  margin: 4px 0;
  padding: 0;
  background-color: #ebebeb;
}

.full-content-list-opt-select.big-blue > .list .separator {
  display: none;
}

.inDepthSelect,
.classSelector {
  position: relative;
  z-index: 17;
}
.inDepthSelect .list,
.classSelector .list {
  max-height: 384px;
  overflow-y: scroll;
}
.inDepthSelect .content,
.classSelector .content {
  padding-left: 0;
}
.inDepthSelect .content .current-value,
.classSelector .content .current-value {
  font-size: 15px;
  color: #424242;
  font-weight: 600;
}

.classSelector {
  z-index: 1;
}
.classSelector .content .current-value {
  font-size: 24px;
}

/* End of file
----------------------------------------------------------------------------------------*//*# sourceMappingURL=listOptSelect.css.map */
/* FORM ELEMENT
----------------------------------------------------------------------------------------*/
html.ie.v-8 input[type=text],
html.ie.v-8 input[type=email],
html.ie.v-8 input[type=number],
html.ie.v-8 input[type=password],
html.ie.v-8 textarea {
  padding-top: 8px !important;
  padding-bottom: 5px !important;
}

input,
select,
textarea,
button {
  -webkit-appearance: none;
  /* Safari/Chrome, other WebKit */
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.hidden-input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: 2;
}

.form-element,
.form-element * {
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, opacity 0.3s ease;
}

.form-element {
  position: relative;
  margin-bottom: 20px;
}

.form-element.margin-bottom-0 {
  margin-bottom: 0;
}

.form-element.error .form-error {
  margin-top: 5px;
  color: #e04f77;
}

.form-element label {
  position: relative;
  display: block;
  padding-bottom: 4px;
}

.form-element:not(.select) input[type=text],
.form-element input[type=email],
.form-element input[type=number],
.form-element input[type=password] {
  position: relative;
  display: block;
  width: 100% !important;
  padding: 0 12px;
  line-height: 40px;
  box-sizing: border-box;
  box-shadow: none;
  outline: 0;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  border-radius: 6px;
  background-color: #fff;
  border: 1px solid #b4b4b4;
  height: 40px;
}

.form-element.select input[type=text] {
  line-height: 32px;
}

.form-element input[type=text]:not(:disabled):focus,
.form-element input[type=email]:not(:disabled):focus,
.form-element input[type=number]:not(:disabled):focus,
.form-element input[type=password]:not(:disabled):focus,
.form-element.focused input[type=text]:not(:disabled),
.form-element.focused input[type=email]:not(:disabled),
.form-element.focused input[type=number]:not(:disabled),
.form-element.focused input[type=password]:not(:disabled) {
  color: #000;
  border-color: #205eff;
}

.form-element.error:not(.select) input[type=text],
.form-element.error input[type=email],
.form-element.error input[type=number],
.form-element.error input[type=password] {
  color: #e04f77 !important;
  border-color: #e04f77 !important;
}

/* .content-profile-picture-field
----------------------------------------------------------------------------------------*/
.content-profile-picture-field {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  overflow: hidden;
  margin-top: 6px;
}

.content-profile-picture-field .picture {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../../res/images/avatar-default.svg");
}

.content-profile-picture-field .hover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  background-image: none;
  background-size: 35px 35px;
  background-position: center center;
  background-repeat: no-repeat;
}

.content-profile-picture-field:not(.disabled):hover .hover {
  background-color: rgba(14, 0, 75, 0.3);
  background-image: url("../../res/images/fields/icon-profile-picture-edit.svg");
}

.content-profile-picture-field input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  font-size: 0;
  cursor: pointer;
  opacity: 0;
}

.content-profile-picture-field.disabled {
  cursor: default;
}

.content-flag-select {
  position: relative;
  display: block;
}

.content-flag-select img {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  width: 22px;
}

/* .password-field
----------------------------------------------------------------------------------------*/
.form-element.password-field .button-password-switch-type {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-image: url("../../res/images/fields/pic-password-off.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}

.form-element.password-field .button-password-switch-type.visible {
  background-image: url("../../res/images/fields/pic-password-on.svg");
}

.form-element.password-field .button-password-switch-type:hover {
  opacity: 0.7;
}

.form-element.password-field input[type=text],
.form-element.password-field input[type=password] {
  padding-right: 40px;
}

.content-password-helper {
  padding: 24px 20px 0;
}

.form-element .content-password-helper label {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-bottom: 0;
}

/* .switch-field
----------------------------------------------------------------------------------------*/
.form-element.switch .switch-libelle {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 13px;
  font-weight: 500;
  color: #969696;
}

.form-element.switch .switch-libelle.left {
  padding-right: 5px;
  color: #3a79e6;
}

.form-element.switch .switch-libelle.right {
  padding-left: 5px;
}

.form-element.switch.checked .switch-libelle.left {
  color: #969696;
}

.form-element.switch.checked .switch-libelle.right {
  color: #3a79e6;
}

.form-element.switch .switch-field {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  height: 34px;
  padding-bottom: 0;
}

/* Hide default HTML checkbox */
.form-element.switch .switch-field input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.form-element.switch .switch-field .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.form-element.switch .switch-field .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.form-element.switch .switch-field input:checked + .slider {
  background-color: #3a79e6;
}

.form-element.switch .switch-field input:focus + .slider {
  box-shadow: 0 0 1px #3a79e6;
}

.form-element.switch .switch-field input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.form-element.switch .switch-field .slider.round {
  border-radius: 34px;
}

.form-element.switch .switch-field .slider.round:before {
  border-radius: 50%;
}

/* .box-input-upload
----------------------------------------------------------------------------------------*/
.box-input-upload {
  height: 148px !important;
  width: 100%;
  background-color: #fafafa;
  position: relative;
  color: #7f7f7f;
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px dashed silver;
}

.box-input-upload.height-140 {
  height: 140px;
}

.box-input-upload.height-100 {
  height: 100px !important;
}

.box-input-upload.on-drag {
  background-color: #3a79e6;
  color: #fff;
  border-color: transparent;
}

.box-input-upload.has-valid-file {
  background-color: #01b96e;
  color: #fff;
}

.box-input-upload.error {
  background-color: #e04f77;
  color: #fff;
}

.box-input-upload .borders {
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  border-radius: 6px;
  border: 2px dashed transparent;
}

.box-input-upload.on-drag .borders {
  border-color: white;
}

.box-input-upload.filed .borders,
.box-input-upload.error .borders {
  border-color: #fff;
}

.box-input-upload input {
  position: absolute;
  width: 100%;
  height: 148px;
  top: 0;
  left: 0;
  font-size: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}

.box-input-upload label {
  font-size: 16px;
  cursor: pointer;
}

.box-input-upload label strong {
  font-weight: 700;
}

.box-input-upload.disabled input,
.box-input-upload.disabled label {
  cursor: default;
}

.uploadBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}

.textBox {
  display: flex;
  position: relative;
  align-items: center;
}

/* upload icon style */
.uploadBox img {
  height: 30px;
  margin-right: 15px;
}

.uploadBox .iconDragHover {
  position: absolute;
  left: 0;
  opacity: 0;
}

.box-input-upload.on-drag .uploadBox .iconDragHover {
  opacity: 1;
}

.box-input-upload.on-drag .uploadBox .iconBase {
  opacity: 0;
}

.uploadBoxTextColored {
  color: #3a79e6;
  font-weight: 600;
}

.box-input-upload.on-drag .uploadBoxTextColored {
  color: black;
}

/* .upload-field-full-content-progress-bar
----------------------------------------------------------------------------------------*/
.upload-field-full-content-progress-bar {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.upload-field-full-content-progress-bar .content-progress-bar {
  position: relative;
  height: 4px;
  background-color: #ebebeb;
  overflow: hidden;
}

.upload-field-full-content-progress-bar .content-progress-bar .progress-bar {
  height: 100%;
  background-image: linear-gradient(to right, #4bd69d 0%, #30beec 100%);
}

.upload-field-full-content-progress-bar .percent-progression {
  margin-top: 5px;
  background: linear-gradient(to bottom, #30beec, #4bd69d);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .content-score-range-field
----------------------------------------------------------------------------------------*/
.content-score-range-field {
  position: relative;
}

.content-score-range-field .header {
  position: relative;
  height: 16px;
}

.content-score-range-field .header .item {
  position: absolute;
  font-size: 13px;
  font-weight: 500;
  color: #969696;
  top: 0;
}

.content-score-range-field .header .item:first-child {
  left: 34.5%;
  transform: translateX(-100%);
}

.content-score-range-field .header .item:last-child {
  left: 64.5%;
}

.content-score-range-field .content {
  position: relative;
  height: 18px;
}

.content-score-range-field .content .bar {
  position: absolute;
  height: 5px;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.content-score-range-field .content .bar .item {
  position: absolute;
  top: 0;
  height: 100%;
  border-radius: 2px;
}

.content-score-range-field .content .bar .item.negative {
  background-color: #e04f77;
}

.content-score-range-field .content .bar .item.neutral {
  background-color: #9f99b7;
}

.content-score-range-field .content .bar .item.positive {
  background-color: #27c986;
}

.content-score-range-field .content .center-bar {
  width: 3px;
  height: 13px;
  border-radius: 5px;
  border: solid 1px #9f99b7;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.content-score-range-field .content .range-container {
  width: 100%;
  position: relative;
  z-index: 3;
}

/* The slider itself */
.content-score-range-field .content .range-container .input-range {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 100%;
  height: 18px;
  background: transparent;
  outline: none;
  opacity: 1;
  top: 0;
  margin: 0;
  padding: 0;
  display: block;
}

.content-score-range-field .content .range-container .input-range:not(.default-range) {
  z-index: 2;
}

.content-score-range-field .content .range-container .input-range.default-range {
  position: absolute;
  height: 9px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.content-score-range-field .content .range-container .input-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border: solid 1.5px #fff;
  box-sizing: border-box;
  background-color: #e04f77;
  cursor: pointer;
}

.content-score-range-field .content .range-container .input-range.neutral::-webkit-slider-thumb {
  background-color: #9f99b7;
}

.content-score-range-field .content .range-container .input-range.positive::-webkit-slider-thumb {
  background-color: #27c986;
}

.content-score-range-field .content .range-container .input-range.default-range::-webkit-slider-thumb {
  width: 9px;
  height: 9px;
  background-color: #fff;
  border-color: #e04f77;
}

.content-score-range-field .content .range-container .input-range.default-range.neutral::-webkit-slider-thumb {
  background-color: #fff;
  border-color: #9f99b7;
}

.content-score-range-field .content .range-container .input-range.default-range.positive::-webkit-slider-thumb {
  background-color: #fff;
  border-color: #27c986;
}

.content-score-range-field .content .range-container .input-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border: solid 1.5px #fff;
  box-sizing: border-box;
  background-color: #e04f77;
  cursor: pointer;
}

.content-score-range-field .content .range-container .input-range.neutral::-moz-range-thumb {
  background-color: #9f99b7;
}

.content-score-range-field .content .range-container .input-range.positive::-moz-range-thumb {
  background-color: #27c986;
}

.content-score-range-field .content .range-container .input-range.default-range::-moz-range-thumb {
  width: 9px;
  height: 9px;
  background-color: #fff;
  border-color: #e04f77;
}

.content-score-range-field .content .range-container .input-range.default-range.neutral::-moz-range-thumb {
  background-color: #fff;
  border-color: #9f99b7;
}

.content-score-range-field .content .range-container .input-range.default-range.positive::-moz-range-thumb {
  background-color: #fff;
  border-color: #27c986;
}

.content-score-range-field .footer {
  position: relative;
  height: 16px;
}

.content-score-range-field .footer .item {
  position: absolute;
  font-size: 13px;
  font-weight: 400;
  color: #000;
  top: 0;
}

.content-score-range-field .footer .item:first-child {
  left: 0;
  text-align: left;
}

.content-score-range-field .footer .item:nth-child(2) {
  text-align: center;
  left: 0;
  right: 0;
}

.content-score-range-field .footer .item:nth-child(3) {
  right: 0;
  left: auto;
  text-align: right;
}

.content-score-range-field .footer .update {
  font-size: 13px;
  font-weight: 400;
  bottom: 0;
  right: 0;
  left: auto;
  text-align: right;
}

.customLabelBis {
  position: relative;
}
.customLabelBis > div > div {
  min-height: 64px;
}
.customLabelBis > div > div > div {
  margin: 10px 0px 5px 0;
}
.customLabelBis::before {
  content: attr(customlabel);
  position: absolute;
  height: 20px;
  top: -10px;
  left: 10px;
  background-color: white;
  padding: 0px 5px;
  color: #9e9e9e;
  font-weight: 600;
  font-size: 15px;
  z-index: 2;
}

/* End of file
----------------------------------------------------------------------------------------*//*# sourceMappingURL=form.css.map */
#scores {
  display: flex;
  column-gap: 26px;
  row-gap: 16px;
  flex-wrap: wrap;
  border-radius: 6px;
  border: 1px solid #eee;
  padding: 10px 16px 8px 16px;
  width: fit-content;
  position: relative;

  &.medium {
    height: 47px;
    column-gap: 14px;
    border: none;
  }

  .separator {
    width: 1px;
    background-color: #eee;

    @media only screen and (min-width: 1068px) {
      height: 35px;
      margin-top: 13px;
      gap: 22px;
    }
  }

  .number-box,
  .feeling-box {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 4px;
    position: relative;

    p {
      font-size: 10px;
      font-weight: 500;
      color: #9e9e9e;
      text-transform: uppercase;
    }

    h3 {
      height: 30px;
      font-size: 24px;
      font-weight: 500;
      color: #000;
      display: flex;
      align-items: flex-end;
      column-gap: 5px;

      @media only screen and (max-width: 1068px) {
        height: 20px;
        font-size: 20px;
      }

      &.medium {
        font-size: 20px;
      }

      &.positive {
        color: #27c986;
      }

      &.negative {
        color: #e04f77;
      }

      .progress-bar {
        height: 5px;
        width: 76px;
        background-color: #eee;
        position: relative;
        bottom: 6px;
        border-radius: 100px;
        overflow: hidden;

        &.medium {
          width: 55px;
        }

        .progress-bar-content {
          height: 100%;
          background-color: #000;
        }
      }
    }
  }

  .feeling-box {
    &.feeling {
      min-width: 130px;
    }

    div {
      display: flex;
      align-items: flex-end;
      column-gap: 4px;

      #color-dot {
        position: relative;
        bottom: 7px;
      }

      h3 {
        font-size: 15px;
        font-weight: normal;
        color: #757575;
        display: flex;
        align-items: flex-end;
        position: relative;
        bottom: 2px;

        &.medium {
          font-size: 15px;
        }
      }
    }

    .c-tooltip {
      height: 30px;
      display: flex;
      align-items: center;

      &__trigger {
        position: relative;
        top: 3px;
      }
    }
  }

  #source-tooltip {
    background-color: transparent;
    position: absolute;
    top: 50px;
    z-index: 17;
    left: -120px;
    display: none;

    @media only screen and (max-width: 1370px) {
      left: -50px;
    }

    @media only screen and (max-width: 1220px) {
      left: -10px;
    }

    #cachetop {
      position: absolute;
      height: 16px;
      width: calc(100% - 8px);
      top: 21px;
      left: 2px;
      background-color: white;
      z-index: 17;
      border-radius: 6px;
    }

    #cachebottom {
      position: absolute;
      height: 16px;
      width: calc(100% - 8px);
      bottom: 0;
      left: 2px;
      background-color: white;
      z-index: 17;
      border-radius: 6px;
    }

    .content {
      width: 270px;
      max-height: 315px;
      padding: 16px;
      border-radius: 6px;
      box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
      border: solid 1px #eee;
      background-color: #fff;
      overflow-y: scroll;
      margin-top: 20px;
      position: relative;

      & + p {
        font-size: 10px;
        font-weight: 500;
        color: #9e9e9e;
      }

      .box {
        &:not(:nth-child(1)) {
          margin-top: 16px;
        }

        .item {
          display: flex;
          align-items: center;
          column-gap: 6px;
          margin-top: 8px;

          &-text {
            width: 100%;
            overflow-x: hidden;

            .title {
              width: 100%;
              font-size: 14px;
              font-weight: 500;
              color: black;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow-x: hidden;
            }

            p {
              text-transform: none;
            }
          }
        }
      }
    }
  }

  #relevant-tooltip {
    background-color: transparent;
    position: absolute;
    top: 50px;
    z-index: 18;
    left: -120px;
    display: none;

    .content {
      width: 270px;
      padding: 16px;
      border-radius: 6px;
      box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
      border: solid 1px #eee;
      background-color: #fff;
      overflow-y: scroll;
      margin-top: 20px;
      position: relative;

      & + p {
        font-size: 10px;
        font-weight: 500;
        color: #9e9e9e;
      }

      .top-infos {
        display: flex;
        justify-content: space-between;

        .relevant-title {
          display: flex;
          align-items: flex-end;
          column-gap: 4px;

          p {
            position: relative;
            bottom: 4px;
            text-transform: none;
            color: #757575;
            font-weight: normal;
          }
        }

        h3:last-child {
          font-size: 15px;
          position: relative;
          bottom: 4px;
        }
      }

      .progress-bar {
        height: 5px;
        width: 100%;
        background-color: #eee;
        border-radius: 100px;
        overflow: hidden;

        .progress-bar-content {
          height: 100%;
          background-color: #000;
        }
      }

      p {
        text-transform: none;
        margin-top: 4px;
        font-size: 12px;
      }

      .last-review {
        display: flex;
        align-items: center;
        column-gap: 6px;
        margin-top: 14px;
      }
    }
  }

  #reviews-tooltip {
    background-color: transparent;
    position: absolute;
    top: 50px;
    z-index: 18;
    left: -120px;
    display: none;

    .content {
      width: 270px;
      padding: 16px;
      border-radius: 6px;
      box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.12),
        0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
      border: solid 1px #eee;
      background-color: #fff;
      overflow-y: scroll;
      margin-top: 20px;
      position: relative;

      & + p {
        font-size: 10px;
        font-weight: 500;
        color: #9e9e9e;
      }

      .top-infos {
        display: flex;
        justify-content: space-between;

        .review-title {
          display: flex;
          align-items: flex-end;
          column-gap: 4px;

          p {
            position: relative;
            bottom: 4px;
            text-transform: none;
            color: #757575;
            font-weight: normal;
          }
        }

        h3:last-child {
          font-size: 15px;
          position: relative;
          bottom: 4px;
        }
      }

      .progress-bar {
        height: 5px;
        width: 100%;
        background-color: #eee;
        border-radius: 100px;
        overflow: hidden;

        .progress-bar-content {
          height: 100%;
          background-color: #000;
        }
      }

      p {
        text-transform: none;
        margin-top: 4px;
        font-size: 12px;
      }

      .last-review {
        display: flex;
        align-items: center;
        column-gap: 6px;
        margin-top: 14px;
      }
    }
  }

  #sources:hover #source-tooltip {
    display: block;
  }

  #reviews:hover #relevant-tooltip {
    display: block;
  }

  #total-reviews:hover #reviews-tooltip {
    display: block;
  }
}

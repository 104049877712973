.claimed-value-bckgrd {
  padding: 16px;
  border-radius: 8px;
  background-color: #fbfbfb;
  height: 100%;
}

.text-limit-6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.positive-percent {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: #27c986;
}

.negative-percent {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: #e04f77;
}

.topTab {
  padding-top: 20px;
  position: relative;
}

.topTab::after {
  content: "";
  height: 90%;
  width: min(1160px, 98vw);
  background-color: white;
  position: absolute;
  top: 140px;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 15px;
}

@media all and (max-width: 920px) {
  .claimed-value-bckgrd {
    width: 100% !important;
    margin-top: 30px;
  }

  .text-limit-6 {
    max-height: 500px;
  }
}

@media all and (max-width: 350px) {
  .project-detail-content-top-column {
    display: block;
    overflow: visible !important;
  }

  .claimed-value-bckgrd {
    margin-bottom: 30px;
  }

  .font-size-title-responsive {
    font-size: 25px;
  }

  .font-size-15-responsive {
    font-size: 12px;
  }

  .display-block-responsive {
    display: block !important;
  }
}

#PageEnd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 60px;

    div {
        text-align: center;
    }

    img {
        width: 100px;
        height: 100px;
    }
}
/* .user-guide-full-content-progress-bar
----------------------------------------------------------------------------------------*/

.user-guide-full-content-progress-bar {
    position: relative;
    padding: 22px 20px;
}

.user-guide-full-content-progress-bar .content-progress-bar {
    position: relative;
    margin-right: 52px;
    height: 12px;
    border-radius: 6px;
    background-color: #ebebeb;
    overflow: hidden;
}

.user-guide-full-content-progress-bar .content-progress-bar .progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    height: 12px;
    background-image: linear-gradient(to right, #4bd69d 0%, #30beec 100%);
}

.user-guide-full-content-progress-bar .percent-progression {
    position: absolute;
    right: 20px;
    top: 18px;
    line-height: 22px;
}

/* .user-guide-accordion
----------------------------------------------------------------------------------------*/

.user-guide-accordion {
    position: relative;
    margin-bottom: 5px;
    padding: 20px 90px 20px 63px;
    border-radius: 6px;
    background-color: #fafafa;
}

.user-guide-accordion .button-open {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: transparent;
    background-size: 24px 24px;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    background-image: url('../../res/images/settings/icon-accordion.svg');
}

.user-guide-accordion .button-open:hover {
    background-color: rgba(255, 255, 255, 0.7);
}

.user-guide-accordion .status {
    position: absolute;
    top: 20px;
    right: 24px;
    text-align: right;
}

.user-guide-accordion .status .icon-completed {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    background-color: transparent;
    background-size: 24px 24px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url('../../res/images/settings/icon-check-tasks.svg');
}

.user-guide-accordion.opened .button-open {
    transform: rotate(90deg);
}

.user-guide-accordion .title {
    color: #000;
    cursor: pointer;
}

.user-guide-accordion.completed .title {
    color: #7f7f7f;
    text-decoration: line-through;
}

.user-guide-accordion .content {
    position: relative;
    padding-top: 4px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.user-guide-accordion.opened .content {
    max-height: 2000px;
    transition: max-height 1s ease-in-out;
}

.user-guide-accordion-button-show-more {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    max-width: 200px;
    padding: 12px;
    cursor: pointer;
    border-radius: 6px;
    background-color: #fafafa;
    box-sizing: border-box;
    color: #000;
}

.user-guide-accordion-button-show-more:hover {
    background-color: #f0f0f0;
}

.user-guide-accordion-button-show-more span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.user-guide-accordion-button-show-more .icon {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-color: transparent;
    background-size: 24px 24px;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    background-image: url('../../res/images/settings/icon-accordion.svg');
    transform: rotate(90deg);
}

.user-guide-accordion-button-show-more.opened .icon {
    transform: rotate(-90deg);
}

.content-all-user-guide-accordion {
    position: relative;
}

.content-all-user-guide-accordion:not(.opened) .user-guide-accordion:not(:first-child) {
    max-height: 0;
    margin-bottom: 0;
    padding: 0;
    overflow: hidden;
    opacity: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
}

.content-all-user-guide-accordion.opened .user-guide-accordion {
    max-height: 2000px;
    transition: max-height 1s ease-in-out;
}

/* OVERVIEW PRICING CSS */

.content-advantages:not(.no-margin-left) {
    margin-left: 20px;
}

.content-advantages .title {
    font-size: 12px;
    font-weight: bold;
    line-height: 1.85;
    color: #000;
    text-align: left;
    padding-bottom: 8px;
}

.content-advantages .raw-advantage {
    display: flex;
}

.content-advantages .advantages-box {
    box-sizing: border-box;
}

.content-advantages:not(.no-height) .advantages-box:first-child {
    height: 205px;
}

.content-advantages .advantages-box .advantage-lst {
    margin-left: 8px;
    font-size: 13px;
    line-height: 1.85;
    color: #7f7f7f;
    text-align: left;
    padding-bottom: 2px;
}

.content-plan-overview-with-dot {
    padding-left: 20px;
    position: relative;
    margin-bottom: 5px;
}

.content-plan-overview-with-dot::before {
    content: "•";
    position: absolute;
    left: 0;
    top: 1px;
}

.btn-plan {
    margin-left: 12px;
}


/* INVOICE TAB CSS */

.invoice-tab .title-tab {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.85;
    color: #000;
}

.invoice-tab .content-tab {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
    color: #7f7f7f;
}

.invoice-tab .invoice-grey-line {
    height: 2px;
    border-radius: 1px;
    background-color: #b4b4b4;
    margin-top: 10px;
    margin-bottom: 13px;
}

.invoice-tab .test-grey-line {
    height: 1px;
    margin: 20px 0px 13px;
    background-color: #d2d2d2;
}

.invoice-tab .link-resend {
    text-align: center;
    font-size: 15px;
    font-weight: 500;
}

.invoice-tab .surline-link {
    text-decoration: underline;
}

.invoice-tab .title-right-box {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    display: inline-block;
    padding-bottom: 10px;
    width: 248px;
}

.invoice-tab .box-right {
    border-radius: 7px;
    border: solid 1px #ebebeb;
    width: 248px;
    display: inline-block;
    text-align: left;
    padding: 14px;
    box-sizing: border-box;
}

@media screen and (max-width: 900px) {

    .invoice-tab .title-right-box,
    .invoice-tab .box-right {
        display: block;
        margin-left: 0;
        width: 100%;
    }

    .invoice-tab .box-right {
        margin-bottom: 40px;
    }
}

.invoice-tab .billing-notif {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: -0.1px;
    color: #7f7f7f;
}

.invoice-tab .btn-invoice {
    padding-top: 16px;
}

.invoice-tab .empty-state-invoice {
    text-align: center;
    padding-top: 65px;
}

/* ACTIVATE MODAL */
.sub-title-activate {
    font-size: 15px;
    line-height: 1.47;
    color: #7f7f7f;
}

/* .delete account
----------------------------------------------------------------------------------------*/
.delete-btn {
    line-height: 34px;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    outline: 0;
    padding: 0 12px;
    text-decoration: none;
    display: inline-block;
    letter-spacing: normal;
    border-radius: 6px;
    background-color: #e04f77;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-align: center;
    color: #fff;
}

.delete-btn-disabled {
    background-color: #d2d2d2;
    cursor: default;
}

.tooltip-delete {
    width: 262px;
    height: 145px;
    border: solid 1px #b4b4b4;
    background-color: #fff;
    border-radius: 6px;
}

.tooltip-delete-text {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.73;
    letter-spacing: -0.2px;
    text-align: left;
    color: #7f7f7f;
    margin: 16px;
}

.text-decoration-underline {
    text-decoration: underline;
}

.font-weight-600 {
    font-weight: 600;
}


/* End of file
----------------------------------------------------------------------------------------*/
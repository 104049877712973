.resume-downgrade {
    font-size: 15px;
    line-height: 1.47;
    text-align: center;
    color: #7f7f7f;
}

.resume-downgrade .expired-date {
    font-weight: bold;
}

.downgrade-box {
    display: block;
    text-align: left;
    border-radius: 8px;
    border: solid 1px #d2d2d2;
    background-color: #fff;
    box-sizing: border-box;
    border-top: 6px solid #f78f64;
    margin: 30px auto 0;
    padding-left: 45px;
    padding-right: 45px;
    padding-bottom: 30px;
    max-width: 350px;
}

.downgrade-box .title {
    padding-top: 24px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.25;
    text-align: center;
    color: #2f2f2f;
    padding-bottom: 6px;
}

.downgrade-box .sub-title {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.33;
    text-align: center;
    color: #969696;
}

.downgrade-box .list-advantages {
    padding-top: 24px;
}

.list-advantages .advantage-raw {
    padding-bottom: 8px;
    display: flex;
}

.list-advantages .advantage-raw .advantage-item {
    font-size: 15px;
    line-height: 1.6;
    color: #7f7f7f;
}

.list-advantages .advantage-raw .icon-check {
    padding-right: 8px;
}

.cancel-btn {
    margin-right: 12px;
}

.testest {
    position: relative;
}

.downgrade-box-content {
    text-align: center;
}

.downgrade-box-content .btn-position-downgrade {
    padding-top: 30px;
}
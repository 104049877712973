.purchase-result .purchase-banner {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    line-height: 26px;
    color: #fff;
    background-color: #f78f64;
    padding: 17px 56px 17px 84px;
    background-size: 26px 26px;
    background-repeat: no-repeat;
    background-position: 56px 18px;
    background-image: url('../../../res/images/icons/pic-check-simple.svg');
}

.purchase-result.error  .purchase-banner {
    background-color: #e04f77;
    background-image: url('../../../res/images/icons/pic-warning.svg');
}

.purchase-result .purchase-box-container {
    padding: 0 0 40px;
    border-radius: 4px;
    border: solid 1px #d2d2d2;
    background-color: #fafafa;
}

.purchase-result .purchase-box-container .plan-title {
    padding-top: 30px;
    font-size: 15px;
    font-weight: bold;
    color: #2f2f2f;
    line-height: 1.47;
}

.purchase-result .purchase-box-container .plan-sub-title {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.47;
    color: #b4b4b4;
    padding-bottom: 30px;
}

.purchase-result .purchase-box-container .receipt-info {
    font-size: 13px;
    font-weight: bold;
    color: #2f2f2f;
}

.purchase-result .purchase-box-container .grey-line {
    border: solid 1px #ebebeb;
    background-color: #f2f2f2;
    margin-bottom: 20px;
}

.purchase-result .purchase-box-container .plan-time {
    font-size: 13px;
    line-height: 1.38;
    color: #7f7f7f;
}

.purchase-result .purchase-box-container .plan-price {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.15px;
    text-align: right;
    color: #7f7f7f;
    padding-bottom: 19.5px;
    padding-left: 100px;
}

.purchase-result .purchase-box-container .plan-price.bold {
    color: black
}

.purchase-result .purchase-box-container .plan-date {
    font-size: 15px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 0.15px;
    text-align: right;
    color: #7f7f7f;
    /* margin-left: 400px; */
    padding-bottom: 19.5px;
}

.purchase-result .purchase-box-container .plan-resume {
    font-size: 13px;
    font-weight: bold;
    line-height: 1.38;
    color: #2f2f2f;
}

.purchase-result .purchase-box-container .using-sensia {
    padding-top: 30px;
    font-size: 13px;
    font-weight: 500;
    color: #7f7f7f;
}

/* ERROR VIEW  */

.purchase-result.error  .purchase-box-container .plan-sub-title {
    padding-bottom: 0px;
}

.error-line {
    padding-top: 20px;
    font-size: 15px;
    font-weight: 500;
    color: #e04f77;
}


.content-media-choice-items {
  position: relative;
  max-width: 1100px;
  margin: 0 auto;
  font-size: 0;
  display: flex;
}

.content-media-choice-items .media-choice-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 160px;
  padding: 0;
  background-color: #fff;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.media-choice-item:not(.disabled):not(.checked) .picture:hover {
  border: solid 1px #3a79e6;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #3a79e6;
}

@media screen and (min-width: 0px) and (max-width: 1120px) {
  .display-only-large-screen {
    display: none;
  } /* show it on smaller screen */
  .mobile-full-width {
    width: 100% !important;
  }
}
@media only screen and (max-width: 750px) {
  .content-media-choice-items {
    flex-wrap: wrap;
    justify-content: center;
  }
  .content-media-choice-items .media-choice-item {
    width: 35%;
  }
}
@media only screen and (max-width: 550px) {
  .content-media-choice-items .media-choice-item {
    width: 51%;
    margin-left: 110px;
  }
}
@media only screen and (max-width: 450px) {
  .content-media-choice-items .media-choice-item {
    width: 51%;
    margin-left: 45px;
  }
}
@media only screen and (max-width: 400px) {
  .content-media-choice-items .media-choice-item {
    width: 51%;
    margin-left: 0px;
  }
}
.content-media-choice-items .media-choice-item input:not(.nothisone) {
  visibility: hidden;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}

.content-media-choice-items .media-choice-item.youtube .picture {
  background-image: url("../../res/images/media-types/youtube/Bt_youtube_unselected.svg");
  width: 176px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid transparent;
  margin: 0 5px 40px 0;
}

.content-media-choice-items .media-choice-item.youtube.disabled .picture {
  background-image: url("../../res/images/media-types/youtube/Bt_youtube_disable.svg");
  width: 176px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid transparent;
  margin: 0 5px 40px 0;
}

.content-media-choice-items .media-choice-item.youtube.checked:not(.disabled) .picture {
  background-image: url("../../res/images/media-types/youtube/Bt_youtube_selected.svg");
  width: 176px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid transparent;
  margin: 0 5px 40px 0;
}

.content-media-choice-items .media-choice-item.web .picture {
  background-image: url("../../res/images/media-types/website/Bt_web_unselected.svg");
  width: 176px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid transparent;
  margin: 0 0 40px 0;
}

/* .content-media-choice-items .media-choice-item.web.disabled.checked .picture {
    background-image: url('../../res/images/media-types/website/Bt_web_disabled_selected.svg');
} */
.content-media-choice-items .media-choice-item.web.disabled:not(.checked) .picture:hover {
  border: solid 1px #ff9378;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #ff9378;
}

.content-media-choice-items .media-choice-item.web.disabled .picture {
  background-image: url("../../res/images/media-types/website/Bt_web_disabled_unselected.svg");
  width: 176px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid transparent;
  margin: 0 0 40px 0;
}

.content-media-choice-items .media-choice-item.web.checked:not(.disabled) .picture {
  background-image: url("../../res/images/media-types/website/Bt_web_selected.svg");
  width: 176px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid transparent;
  margin: 0 0 40px 0;
}

.content-media-choice-items .media-choice-item.amazon .picture {
  background-image: url("../../res/images/media-types/amazon/Bt_amazon_unselected.svg");
  width: 176px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid transparent;
}

.content-media-choice-items .media-choice-item.amazon.disabled .picture {
  background-image: url("../../res/images/media-types/amazon/Bt_amazon_disable.svg");
  width: 176px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid transparent;
  margin: 0 5px 40px 0;
}

.content-media-choice-items .media-choice-item.amazon.checked:not(.disabled) .picture {
  background-image: url("../../res/images/media-types/amazon/Bt_amazon_selected.svg");
  width: 176px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid transparent;
  margin: 0 5px 40px 0;
}

.content-media-choice-items .media-choice-item.file .picture {
  background-image: url("../../res/images/media-types/file/Bt_file_unselected.svg");
  width: 176px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid transparent;
  margin: 0 5px 40px 0;
}

.content-media-choice-items .media-choice-item.file.disabled .picture {
  background-image: url("../../res/images/media-types/file/Bt_file_disabled_unselected.svg");
  width: 176px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid transparent;
  margin: 0 5px 40px 0;
}

/* .content-media-choice-items .media-choice-item.file.disabled.checked .picture {
    background-image: url('../../res/images/media-types/file/Bt_file_disabled_selected.svg');
} */
.content-media-choice-items .media-choice-item.file.disabled:not(.checked) .picture:hover {
  border: solid 1px #ff9378;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #ff9378;
}

.content-media-choice-items .media-choice-item.file.checked:not(.disabled) .picture {
  background-image: url("../../res/images/media-types/file/Bt_file_selected.svg");
  width: 176px;
  height: 160px;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid transparent;
  margin: 0 5px 40px 0;
}

.content-information-media,
.content-information-url,
.content-block-error {
  position: relative;
  padding: 10px;
  border-radius: 6px;
  background-color: #fafafa;
  font-size: 15px;
  color: #000;
  box-sizing: border-box;
}

.content-information-media.error,
.content-information-url.error,
.content-block-error {
  color: #e04f77;
  font-weight: 600;
}

.form-message-error {
  color: #e04f77;
  font-size: 15px;
}

.content-information-media.success .content-information-url.success {
  height: 100px;
}

.content-information-media .close-btn,
.content-information-url .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  z-index: 3;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  background-image: url("../../res/images/pic-close-modal.svg");
  transition: opacity 0.3s ease;
}

.content-information-media .close-btn:hover,
.content-information-url .close-btn:hover {
  opacity: 0.8;
}

.content-information-media .picture,
.content-information-url .picture {
  position: absolute;
  width: 125px;
  height: 80px;
  top: 10px;
  left: 10px;
  border-radius: 2px;
}

.content-information-media.txt-file .picture {
  background-image: url("../../res/images/media-types/txt-file-default.png");
}

.content-information-media.website-url .picture {
  background-image: url("../../res/images/media-types/website-url-default.png");
}

.content-information-media.amazon-url .picture {
  background-image: url("../../res/images/media-types/amazon-url-default.png");
}

.content-information-media.youtube-url .picture {
  background-image: url("../../res/images/media-types/youtube-url-default.png");
}

.content-information-media .content-left,
.content-information-url .content-left {
  position: relative;
  margin-left: 135px;
  padding-right: 15px;
  height: 80px;
}

.content-captions-infos:not(.content-captions-infos.failed) {
  position: absolute;
  bottom: 0;
}

.content-captions-infos span {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.content-captions-infos .icon-captions {
  width: 16px;
  height: 16px;
  background-image: url("../../res/images/caption/pic-analyse-ok.svg");
}

.content-captions-infos .icon-captions.ko {
  width: 20px;
  height: 15px;
  background-image: url("../../res/images/caption/pic-analyse-ko.svg");
}

.content-captions-infos .icon-captions.v2 {
  width: 20px;
  height: 15px;
  background-image: url("../../res/images/caption/pic-captions-v-2.svg");
}

.content-captions-infos .icon-captions.ko.v2 {
  width: 20px;
  height: 15px;
  background-image: url("../../res/images/caption/pic-captions-nok-v-2.svg");
}

.content-error-plan-media {
  position: relative;
  padding: 14px;
  padding-left: 40px;
  border-radius: 6px;
  background-color: #feeee7;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  margin-bottom: 55px;
}

.content-error-plan-media .icon {
  position: absolute;
  left: 14px;
  top: 12px;
}

.media-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 15px 0;
  border-bottom: 1px solid #ebebeb;
  z-index: 10;
  background-color: #fff;
}

.media-header.fake {
  position: relative;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.media-header .logo a {
  display: block;
  margin: 0;
}

.media-header .logo a img {
  display: block;
  margin: 0;
}

.media-header .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  display: block;
}

.media-header .close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 3;
  background-position: center center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  background-image: url("../../res/images/pic-close-modal-big.svg");
  transition: opacity 0.3s ease;
}

.media-header .close:hover {
  opacity: 0.8;
}

.height-650 {
  height: 650px;
}

@media screen and (max-width: 600px) {
  .media-header .title {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin-top: 20px;
  }
}
.container-tests .test {
  display: flex;
  margin: 5px 0;
  align-items: center;
}

.container-tests .test input[type=radio] {
  width: 24px;
  height: 24px;
  background-image: url("../../res/images/bt-checkbox-off.svg");
  margin-right: 10px;
  cursor: pointer;
}

.container-tests .test input[type=radio]:checked {
  border: none;
  background-image: url("../../res/images/bt-checkbox-on.svg");
}

.infoBox {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background-color: #fafafa;
  border-radius: 8px;
  padding: 8px;
  margin-top: 20px;
}
.infoBox p {
  display: flex;
  align-items: center;
}
.infoBox p:first-child {
  color: #3a79e6;
  font-weight: 500;
}
.infoBox p:first-child span {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  border: 2px solid #3a79e6;
  border-radius: 100%;
  color: #3a79e6;
  margin-right: 8px;
  font-weight: 600;
  font-size: 12px;
}

.containerSelectSheet {
  display: flex;
  max-width: 1100px;
  justify-content: space-between;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .containerSelectSheet {
    justify-content: center;
  }
}
@media only screen and (max-width: 1130px) {
  .containerSelectSheet {
    margin: 0 15px;
  }
}
.containerSelectSheet .selectSheetView {
  width: 70%;
  margin: 0;
  justify-content: start;
}

#illustrationSelectSheet {
  width: 70%;
  height: 645px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../res/images/union.svg");
  background-size: 95%;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width: 768px) {
  #illustrationSelectSheet {
    display: none;
  }
}
#illustrationSelectSheet #box,
#illustrationSelectSheet #confirmbox {
  width: 70%;
  max-width: 320px;
  aspect-ratio: 1/1.5;
  min-height: 420px;
  padding: 16px 26px 30px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
#illustrationSelectSheet #box img,
#illustrationSelectSheet #confirmbox img {
  width: 120px;
  height: 90px;
  margin-bottom: 25px;
}
#illustrationSelectSheet #box img + p,
#illustrationSelectSheet #confirmbox img + p {
  font-weight: 600;
}
#illustrationSelectSheet #box .skeleton,
#illustrationSelectSheet #confirmbox .skeleton {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}
#illustrationSelectSheet #box .skeleton:nth-child(3),
#illustrationSelectSheet #confirmbox .skeleton:nth-child(3) {
  margin-top: 60px;
}
#illustrationSelectSheet #box .skeleton div,
#illustrationSelectSheet #confirmbox .skeleton div {
  height: 7px;
  width: 50px;
  border-radius: 100px;
  background-color: rgba(224, 224, 224, 0.8784313725);
}
#illustrationSelectSheet #box .skeleton div:nth-child(1), #illustrationSelectSheet #box .skeleton div:nth-child(2),
#illustrationSelectSheet #confirmbox .skeleton div:nth-child(1),
#illustrationSelectSheet #confirmbox .skeleton div:nth-child(2) {
  width: 20%;
}
#illustrationSelectSheet #box .skeleton div:nth-child(3),
#illustrationSelectSheet #confirmbox .skeleton div:nth-child(3) {
  width: 15%;
}
#illustrationSelectSheet #box .skeleton div:nth-child(4),
#illustrationSelectSheet #confirmbox .skeleton div:nth-child(4) {
  width: 30%;
}
#illustrationSelectSheet #box .skeleton div:nth-child(5), #illustrationSelectSheet #box .skeleton div:nth-child(6),
#illustrationSelectSheet #confirmbox .skeleton div:nth-child(5),
#illustrationSelectSheet #confirmbox .skeleton div:nth-child(6) {
  width: 25%;
}
#illustrationSelectSheet #box .skeleton div:nth-child(7),
#illustrationSelectSheet #confirmbox .skeleton div:nth-child(7) {
  width: 15%;
}
#illustrationSelectSheet #box .skeleton div:nth-child(8),
#illustrationSelectSheet #confirmbox .skeleton div:nth-child(8) {
  width: 20%;
}
#illustrationSelectSheet #box .skeleton div:nth-child(9),
#illustrationSelectSheet #confirmbox .skeleton div:nth-child(9) {
  width: 30%;
}
#illustrationSelectSheet #box .skeleton div:nth-child(10),
#illustrationSelectSheet #confirmbox .skeleton div:nth-child(10) {
  width: 15%;
}
#illustrationSelectSheet #box #bottom,
#illustrationSelectSheet #confirmbox #bottom {
  width: 100%;
  height: 64px;
  border-top: 2px solid rgba(224, 224, 224, 0.8784313725);
  position: absolute;
  left: 0px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#illustrationSelectSheet #box #bottom div,
#illustrationSelectSheet #confirmbox #bottom div {
  width: 30%;
  height: 7px;
  border-radius: 100px;
  background-color: rgba(224, 224, 224, 0.8784313725);
}
#illustrationSelectSheet #confirmbox {
  height: 308px;
}
#illustrationSelectSheet #confirmbox p {
  align-self: flex-start;
  width: 100%;
  word-wrap: break-word;
}
#illustrationSelectSheet #confirmbox #typology {
  margin-top: 10px;
  color: #7f7f7f;
  font-size: 16px;
}
#illustrationSelectSheet #confirmbox .fieldPreview {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
  width: 100%;
  margin-top: 15px;
}
#illustrationSelectSheet #confirmbox .fieldPreview:nth-child(4) {
  margin-top: 20px !important;
}
#illustrationSelectSheet #confirmbox .fieldPreview img {
  width: 20px;
  height: 20px;
  -o-object-fit: cover;
     object-fit: cover;
  margin: 0;
}
#illustrationSelectSheet #confirmbox .fieldPreview p {
  font-size: 15px;
  font-weight: 400;
  color: #0e004b;
}
#illustrationSelectSheet #confirmbox .fieldPreview p strong {
  color: #000;
}

.detailsList {
  margin-top: 35px;
}
.detailsList div {
  font-weight: normal;
}
.detailsList.select-field div div div {
  min-height: 50px;
  max-height: 130px;
  overflow-y: scroll;
  font-size: 15px;
}
.detailsList.select-field div div div div {
  display: flex;
  align-items: center;
  min-height: 32px;
}
.detailsList.select-field div div div .css-1rhbuit-multiValue {
  border: 2px solid #616161;
  border-radius: 4px;
  background-color: transparent;
}
.detailsList.select-field p {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.detailsList.select-field p:nth-child(1) {
  font-weight: 600;
}
.detailsList.select-field p:nth-child(2) {
  font-size: 15px;
  color: #7f7f7f;
  margin-bottom: 16px;
}
.detailsList.select-field p img {
  width: 20px;
  height: 20px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 10px;
}

.detailsListSelect {
  margin-top: 51px;
}
.detailsListSelect div {
  font-weight: normal !important;
}
.detailsListSelect.select-field p {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.detailsListSelect.select-field p:nth-child(1) {
  font-weight: 600;
}
.detailsListSelect.select-field p:nth-child(2) {
  font-size: 15px;
  color: #7f7f7f;
  margin-bottom: 16px;
}
.detailsListSelect.select-field p img {
  width: 20px;
  height: 20px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-right: 10px;
}

[class$=-indicatorSeparator] {
  display: none;
}/*# sourceMappingURL=addMedia.css.map */
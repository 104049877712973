$blue-color: #3a79e6;
$gray-title: #616161;
$black: #000000;

#projectFilters {
  position: relative;

  button {
    &:hover {
      cursor: pointer;
    }
  }

  #filtersBtn {
    width: 105px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 8px;
    border: solid 1px transparent;
    border-radius: 6px;
    background-color: white;
    font-size: 15px;
    font-weight: 500;
    color: $blue-color;
    transition: 150ms ease-in-out;

    #filters-arrow {
      transform: rotate(180deg);

      &.active {
        transform: rotate(0);
      }
    }

    &.active {
      background-color: rgba(48, 110, 214, 0.04);
      border: solid 1px $blue-color;
    }

    &:hover {
      cursor: pointer;
    }
  }

  #filtersView {
    position: relative;
    width: calc(100% - 30px);
    top: 0;
    left: 10px;
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
    z-index: 1;

    .tag {
      height: 32px;
      max-width: 205px;
      display: flex;
      align-items: center;
      padding: 0px 10px;
      padding-right: 5px;
      border: solid 1px #306ed6;
      border-radius: 4px;

      p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
        color: $blue-color;
        font-size: 15px;
      }
    }

    button {
      font-size: 15px;
      font-weight: 500;
      color: $blue-color;
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0;
      margin-left: 8px;
    }
  }

  #filters-content {
    height: auto;
    max-height: 500px;
    overflow-y: scroll;
    position: absolute;
    top: calc(100% + 10px);
    z-index: -2;
    background-color: #fff;
    box-shadow: 0 4px 4px 0 #00000040;
    transition: 150ms ease-in-out;
    transform: scale(0.5);
    transform-origin: center top;
    opacity: 0;
    visibility: hidden;
    padding: 20px 16px 14px 16px;
    width: 320px;

    #filters-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 6px;
      row-gap: 16px;

      .filter {
        overflow-y: hidden;
        transition: 150ms ease-in-out;
        position: relative;
        height: 20px;

        #clickArea {
          content: "";
          width: 100%;
          height: 20px;
          position: absolute;
          top: 0;
          background-color: transparent;
          z-index: 1;

          &:hover {
            cursor: pointer;
          }
        }

        .lock {
          justify-content: start;
          gap: 10px;
        }

        h3 {
          height: 20px;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.1px;
          color: $black;
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin-bottom: 16px;
          text-transform: uppercase;

          span {
            overflow-x: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          img:last-child {
            transform: rotate(180deg);

            &.active-arrow {
              transform: rotate(0);
            }
          }
        }

        .filters {
          margin-left: 10px;
          margin-right: 10px;

          .MuiFormControlLabel-label {
            display: flex;
            align-items: center;
          }

          img + span {
            margin-left: 8px;
          }
        }

        span.MuiCheckbox-root {
          padding: 4px 8px;
        }

        &.active-filter {
          height: auto;
        }
      }
    }

    &.active {
      transform: scale(1);
      opacity: 1;
      background-color: white;
      z-index: 2;
      visibility: visible;
    }
  }
}

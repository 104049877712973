#skeletonLoad {
  display: flex;
  flex-direction: column;
}
@keyframes opacityAnimation {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
#skeletonLoad .skelontItem {
  background-color: #f4f8ff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
#skeletonLoad .skelontItem.right-effect:after {
  content: "";
  width: 100px;
  height: 150%;
  background-color: white;
  opacity: 0.4;
  position: absolute;
  right: 20%;
  top: 50%;
  transform: rotate(10deg) translateY(-50%);
}

.skeleton-box {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #f4f8ff;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 3s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}/*# sourceMappingURL=SkeletonLoad.css.map */
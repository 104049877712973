.view-class-plan-message {
    position: relative;
    padding: 5px 12px;
    border-radius: 4px 4px 0 0;
    background-color: #b4b4b4;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.23;
    margin-bottom: -2px;
    z-index: 2;
}

.view-class-plan-message .icon {
    position: absolute;
}

.view-class-plan-message .text {
    padding-left: 22px;
}

.view-class-readonly textarea {
    border-radius: 0 0 6px 6px;
}

.typology-checkboxes,
.typology-checkboxes-createClass {
    display: flex;
    border-color: silver !important;
}

.typology-checkboxes .checkbox .consumer-checkbox,
.typology-checkboxes-createClass .checkbox .consumer-checkbox {
    padding-left: 0 !important;
}

.typology-checkboxes .checkbox .label-disabled {
    color: #A1A1A1;
}

.typology-checkboxes .checkbox .typology-label,
.typology-checkboxes-createClass .checkbox .typology-label {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
}
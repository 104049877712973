#PageEnd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 60px;
}
#PageEnd div {
  text-align: center;
}
#PageEnd img {
  width: 100px;
  height: 100px;
}/*# sourceMappingURL=PageEnd.css.map */
#LineAreaGraphComponents {
    border-radius: 8px;
    border: solid 1px #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    #fond {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        top: 0;
        left: 0;
        background-color: #f5f5f5;

        &:before {
            content: "";
            width: 70px;
            height: 150%;
            background-color: white;
            opacity: .4;
            position: absolute;
            right: 20%;
            top: 50%;
            transform: rotate(3deg) translateY(-50%);
        }
    
        &:after {
            content: "";
            width: 70px;
            height: 150%;
            background-color: white;
            opacity: .4;
            position: absolute;
            right: 40%;
            top: 50%;
            transform: rotate(3deg) translateY(-50%);
        }
    }

    #header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 15px;

        [role=button] {
            font-size: 15px !important;
            font-weight: 500;
        }

        #title {
            font-weight: 500;
            text-transform: uppercase;
            color: #9e9e9e;
            font-size: 12px;

            &+div {
                position: relative;
                width: 120px;

                div {
                    height: 25px;
                    padding: 0;
                }

                label {
                    display: none;
                }

                #demo-simple-select {
                    position: absolute;
                    right: -5px;
                }

                *:not(svg) {
                    color: #7f7f7f;
                    border: none;
                    font-size: 15px;
                    font-weight: 500;
                }

                svg {
                    top: calc(50% - 0.55em);
                }
            }
        }
    }

    #box {
        padding: 15px;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        background-color: white;

        h3+p {
            margin-top: 5px;
        }

        .col-box {
            display: flex;
            align-items: center;
            // justify-content: center;

            .col-box-item {
                width: 45%;
            }

            // &:nth-child(3) {
            //     margin-bottom: 18px;
            // }

            h3 {
                font-size: 20px;
            }
        }
    }

    #legend {
        margin-bottom: 16px;
        display: flex;
        column-gap: 15px;

        .legend-item {
            font-size: 12px;
            color: #9e9e9e;
            display: flex;
            align-items: center;
            column-gap: 6px;

            #project {
                width: 7px;
                height: 7px;
                background-color: #27c985;
                border-radius: 100%;
            }

            #industry {
                width: 12px;
                border: 1px dashed #f78f64;
            }
        }
    }

    svg:hover {
        cursor: pointer;
    }
}
/* Report HEADER
----------------------------------------------------------------------------------------*/

.report-option {
  position: relative;
  padding: 8px 12px;
  background-color: transparent;
}

.report-option,
.report-option * {
  cursor: default;
}

.report-option:hover,
.report-option.isFocused,
.report-option.isSelected {
  background-color: #deebff;
}

.report-option .keyword-note {
  float: right;
}

.select-keyword input[type="text"] {
  height: 34px;
  line-height: 34px;
  margin: 0;
  position: relative;
  top: -2px;
}

.margin-top-12-important {
  margin-top: 12px !important;
}

/* #selection-tooltip
----------------------------------------------------------------------------------------*/

#selection-tooltip {
  position: absolute;
  transform: translateX(30%);
  z-index: 8;
}

/* claimed value indicators

----------------------------------------------------------------------------------------*/

.claimed-value-bckgrd {
  padding: 16px;
  border-radius: 8px;
  background-color: #fbfbfb;
  height: 100%;
}

.text-limit-6 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.text-limit-12 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 12;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

.positive-percent {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: #27c986;
}

.negative-percent {
  font-family: "Inter", sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: #e04f77;
}

@media all and (max-width: 920px) {
  .claimed-value-bckgrd {
    width: 100% !important;
    margin-top: 30px;
  }

  .text-limit-6 {
    max-height: 500px;
  }
}

@media all and (max-width: 350px) {
  .project-detail-content-top-column {
    display: block;
    overflow: visible !important;
  }

  .claimed-value-bckgrd {
    margin-bottom: 30px;
  }

  .font-size-title-responsive {
    font-size: 25px;
  }

  .font-size-15-responsive {
    font-size: 12px;
  }
}

.filerItems span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.full-content-list-opt-select.inDepthSelect .content .current-value::after {
  top: 5px;
  right: 0px;
  border: none;
  width: 20px;
  height: 20px;
  content: "";
  background: url("../../res/images/icons/expand-more-filled.svg");
  background-position: center center;
  background-size: cover;
  transform: rotate(0deg);
}

#fond {
  width: 100vw;
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  left: 0;
  background-color: rgba(14, 0, 75, 0.3019607843);
  z-index: 19;
}

.sensia-popup {
  padding: 26px;
  width: 50vw;
  min-width: 300px;
  max-width: 800px;
  border-radius: 12px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: fixed;
  z-index: 20;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
}
.sensia-popup .close {
  position: absolute;
  top: 18px;
  right: 18px;
}
.sensia-popup .close:hover {
  cursor: pointer;
}

.share-tooltip,
.share-tooltip-free {
  padding: 26px;
  width: 50vw;
  min-width: 300px;
  max-width: 560px;
  border-radius: 12px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: fixed;
  z-index: 20;
  left: 50%;
  top: 50vh;
  transform: translate(-50%, -50%);
}
.share-tooltip h3,
.share-tooltip-free h3 {
  font-size: 24px;
  font-weight: bold;
  color: #424242;
}
.share-tooltip p,
.share-tooltip-free p {
  font-size: 16px;
  font-weight: 500;
  color: #757575;
  display: flex;
  align-items: center;
  -moz-column-gap: 8px;
       column-gap: 8px;
  margin-top: 26px;
  margin-bottom: 28px;
}
.share-tooltip a,
.share-tooltip-free a {
  text-decoration: none;
}
.share-tooltip .close,
.share-tooltip-free .close {
  position: absolute;
  top: 18px;
  right: 18px;
}
.share-tooltip .close:hover,
.share-tooltip-free .close:hover {
  cursor: pointer;
}
.share-tooltip .actions,
.share-tooltip-free .actions {
  font-size: 15px;
  font-weight: 500;
  color: #6d9eee;
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.share-tooltip .btn-box,
.share-tooltip-free .btn-box {
  display: flex;
  justify-content: space-between;
}
.share-tooltip .btn-box span:hover,
.share-tooltip-free .btn-box span:hover {
  cursor: pointer;
}
.share-tooltip .btn-box .content,
.share-tooltip-free .btn-box .content {
  display: flex;
  align-items: center;
  -moz-column-gap: 28px;
       column-gap: 28px;
  width: 100%;
}
.share-tooltip .btn-box .preview,
.share-tooltip-free .btn-box .preview {
  display: flex;
  align-items: center;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.share-tooltip .btn-box .button,
.share-tooltip-free .btn-box .button {
  justify-self: flex-end;
}
.share-tooltip .MuiPaper-root,
.share-tooltip-free .MuiPaper-root {
  margin-top: 0px;
  box-shadow: none !important;
  border: none;
  background-color: transparent !important;
  position: relative;
  overflow: visible !important;
}
.share-tooltip .MuiPaper-root .MuiMenuItem-root,
.share-tooltip-free .MuiPaper-root .MuiMenuItem-root {
  padding-left: 6px;
  padding-right: 6px;
  position: relative;
  left: 2px;
  bottom: 10px;
  overflow: visible !important;
}
.share-tooltip .MuiPaper-root .MuiMenuItem-root:hover,
.share-tooltip-free .MuiPaper-root .MuiMenuItem-root:hover {
  background-color: transparent;
  position: relative;
}
.share-tooltip .MuiPaper-root .MuiMenuItem-root:hover::before,
.share-tooltip-free .MuiPaper-root .MuiMenuItem-root:hover::before {
  content: "";
  width: 35px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.0392156863);
  position: absolute;
  right: 1.5px !important;
  z-index: 0;
  border-radius: 100%;
}

.share-tooltip-free {
  position: fixed;
}

#long-menu-perso .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters:hover {
  background-color: transparent;
  position: relative;
}
#long-menu-perso .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters:hover::before {
  content: "";
  width: 35px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.0392156863);
  position: absolute;
  right: 1.5px !important;
  z-index: 0;
  border-radius: 100%;
}/*# sourceMappingURL=style.css.map */
#radarGraphComponents {
    border-radius: 8px;
    border: solid 1px #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    #header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 15px;

        #title {
            font-size: 12px;
            font-weight: 500;
            text-transform: uppercase;
            color: #9e9e9e;

            &+div {
                width: 90px;

                div {
                    height: 25px;
                    padding: 0;
                }

                label {
                    display: none;
                }

                *:not(svg) {
                    color: #7f7f7f;
                    border: none;
                    font-size: 15px;
                    font-weight: 500;
                }

                svg {
                    top: calc(50% - 0.55em);
                }
            }
        }
    }

    tspan {
        font-size: 12px;
        font-weight: 500;
        color: #757575;
    }

    #box {
        padding: 15px;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        background-color: white;

        h3+p {
            margin-top: 5px;
            margin-bottom: 15px;
        }

        .col-box {
            display: flex;
            align-items: center;
            // justify-content: center;

            .col-box-item {
                width: 45%;
            }

            // &:nth-child(3) {
            //     margin-bottom: 18px;
            // }

            h3 {
                font-size: 20px;
            }
        }
    }

    #legend {
        margin-bottom: 16px;
        display: flex;
        column-gap: 15px;

        .legend-item {
            font-size: 12px;
            color: #9e9e9e;
            display: flex;
            align-items: center;
            column-gap: 6px;

            #project {
                width: 7px;
                height: 7px;
                background-color: #3a79e6;
                border-radius: 100%;
            }

            #industry {
                width: 12px;
                border: 1px dashed #f78f64;
            }
        }
    }

    svg:hover {
        cursor: pointer;
    }
}
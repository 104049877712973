/* Class list item
----------------------------------------------------------------------------------------*/

.content-class-list-item {
  position: relative;
  margin-bottom: 5px;
}
.content-checkbox {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
}
.content-class-list-item .content-checkbox {
  position: absolute;
  display: block;
  width: 40px;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}
.content-checkbox input {
  visibility: hidden;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}
.content-checkbox .fake-checkbox {
  position: absolute;
  display: block;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../../res/images/bt-checkbox-off.svg");
  top: 0;
}
.content-class-list-item .content-checkbox .fake-checkbox {
  top: 0;
}
.content-checkbox input:not(:disabled) + .fake-checkbox {
  cursor: pointer;
}
.content-checkbox input:checked + .fake-checkbox {
  background-image: url("../../res/images/bt-checkbox-on.svg");
}
.content-checkbox input:checked:disabled + .fake-checkbox {
  background-image: url("../../res/images/bt-checkbox-on-disabled.svg");
}

.content-class-list-item .content-class-list {
  position: relative;
  display: block;
  height: 100%;
  padding: 12px 10px 12px 16px;
  margin-left: 40px;
  border-radius: 6px;
  background-color: #fafafa;
  border: solid 1px #fafafa;
  box-sizing: border-box;
}
.content-class-list-item .content-class-list.isOther {
  margin-left: 0;
}

.content-class-list-item .content-class-list.open-modal-onclick {
  background-size: 30px 30px;
  background-repeat: no-repeat;
  background-image: url("../../res/images/bt-options-uns.svg");
  background-position: 99% center;
  cursor: pointer;
}

.button-position {
  position: absolute;
  right: 10px;
  top: 18px;
}

.content-edit-button {
  position: absolute;
  right: 56px;
  top: 16px;
  opacity: 0;
}
.content-class-list-item .content-class-list:hover {
  border: solid 1px #3a79e6;
  background-color: #fff;
}

.content-class-list-item .content-class-list:hover .content-edit-button {
  opacity: 1;
}

/* Group Class list item
----------------------------------------------------------------------------------------*/

.content-group-class-list-item {
  position: relative;
  margin-bottom: 25px;
}

.content-group-class-list-item .content-checkbox {
  position: absolute;
  display: block;
  width: 40px;
  cursor: pointer;
  top: 0;
}
.content-group-class-list-item .content-checkbox input {
  visibility: hidden;
  opacity: 0;
  display: block;
  width: 0;
  height: 0;
}
.content-group-class-list-item .content-checkbox .fake-checkbox {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("../../res/images/bt-checkbox-off.svg");
  top: 0;
}
.content-group-class-list-item
  .content-checkbox
  input:not(:disabled)
  + .fake-checkbox {
  cursor: pointer;
}
.content-group-class-list-item
  .content-checkbox
  input:checked
  + .fake-checkbox {
  background-image: url("../../res/images/bt-radio-on.svg");
}

.content-group-class-list-item .content-class-list {
  position: relative;
  display: inline-block;
  margin-left: 40px;
}

/* End of file
----------------------------------------------------------------------------------------*/

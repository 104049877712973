.filerItems span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.full-content-list-opt-select.inDepthSelect .content .current-value::after {
  top: 5px;
  right: 0px;
  border: none;
  width: 20px;
  height: 20px;
  content: "";
  background: url("../../res/images/icons/expand-more-filled.svg");
  background-position: center center;
  background-size: cover;
  transform: rotate(0deg);
}

#fond {
  width: 100vw;
  height: calc(100vh - 60px);
  position: fixed;
  top: 60px;
  left: 0;
  background-color: #0e004b4d;
  z-index: 19;
}

.sensia-popup {
  padding: 26px;
  width: 50vw;
  min-width: 300px;
  max-width: 800px;
  border-radius: 12px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: fixed;
  z-index: 20;
  left: 50%;
  top: calc((100vh / 2));
  transform: translate(-50%, -50%);

  .close {
    position: absolute;
    top: 18px;
    right: 18px;

    &:hover {
      cursor: pointer;
    }
  }
}

.share-tooltip,
.share-tooltip-free {
  padding: 26px;
  width: 50vw;
  min-width: 300px;
  max-width: 560px;
  border-radius: 12px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
  position: fixed;
  z-index: 20;
  left: 50%;
  top: calc((100vh / 2));
  transform: translate(-50%, -50%);

  h3 {
    font-size: 24px;
    font-weight: bold;
    color: #424242;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    color: #757575;
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-top: 26px;
    margin-bottom: 28px;
  }

  a {
    text-decoration: none;
  }

  .close {
    position: absolute;
    top: 18px;
    right: 18px;

    &:hover {
      cursor: pointer;
    }
  }

  .actions {
    font-size: 15px;
    font-weight: 500;
    color: #6d9eee;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  .btn-box {
    display: flex;
    justify-content: space-between;

    span:hover {
      cursor: pointer;
    }

    .content {
      display: flex;
      align-items: center;
      column-gap: 28px;
      width: 100%;
    }

    .preview {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    .button {
      justify-self: flex-end;
    }
  }

  .MuiPaper-root {
    margin-top: 0px;
    box-shadow: none !important;
    border: none;
    background-color: transparent !important;
    position: relative;
    overflow: visible !important;

    & .MuiMenuItem-root {
      padding-left: 6px;
      padding-right: 6px;
      position: relative;
      left: 2px;
      bottom: 10px;
      overflow: visible !important;

      &:hover {
        background-color: transparent;
        position: relative;

        &::before {
          content: "";
          width: 35px;
          height: 35px;
          background-color: #0000000a;
          position: absolute;
          right: 1.5px !important;
          z-index: 0;
          border-radius: 100%;
        }
      }
    }
  }
}

.share-tooltip-free {
  position: fixed;
}

#long-menu-perso {
  .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
    &:hover {
      background-color: transparent;
      position: relative;

      &::before {
        content: "";
        width: 35px;
        height: 35px;
        background-color: #0000000a;
        position: absolute;
        right: 1.5px !important;
        z-index: 0;
        border-radius: 100%;
      }
    }
  }
}

.restore-button {
    margin-left: -11px;
}

.save-cancel-button {
    margin-right: -11px
}

.close-btn-model-upload {
    position: absolute;
    top: 30px;
    right: 10px;
    width: 10px;
    height: 10px;
    cursor: pointer;
    z-index: 3;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 32px 32px;
    background-image: url('../../res/images/pic-close-modal-big.svg');
    transition: opacity 0.3s ease;
}

.position-relative {
    position: relative;
}
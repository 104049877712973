.plan-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 15px 0;
  border-bottom: 1px solid #ebebeb;
  z-index: 10;
  background-color: #fff;
}

.plan-header.fake {
  position: relative;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
}

.plan-header .logo a {
  display: block;
  margin: 0;
}
.plan-header .logo a img {
  display: block;
  margin: 0;
}

.plan-header .title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: 18px;
  line-height: 1.33;
  text-align: center;
  display: block;
}

.plan-header .close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 3;
  background-position: center center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  background-image: url("../../res/images/pic-close-modal-big.svg");
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.plan-header .close:hover {
  opacity: 0.8;
}

@media screen and (max-width: 600px) {
  .plan-header .title {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    margin-top: 20px;
  }
}

.content-raw-advantages .raw-advantage {
  display: flex;
}
.content-raw-advantages .raw-advantage .advantage-lst {
  margin-left: 8px;
  font-size: 13px;
  line-height: 1.85;
  color: #7f7f7f;
  text-align: left;
  padding-bottom: 2px;
}

#test-zone {
  height: calc(100vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
#test-zone h1 {
  width: 95%;
  text-align: left;
  font-size: 25px;
  text-transform: uppercase;
  color: red;
}
#test-zone #test-area {
  width: 95%;
  height: 90%;
  display: flex;
  -moz-column-gap: 20px;
       column-gap: 20px;
  align-items: flex-start;
}/*# sourceMappingURL=testGraphComponents.css.map */
/* Report HEADER
----------------------------------------------------------------------------------------*/

.report-option {
    position: relative;
    padding: 8px 12px;
    background-color: transparent;
}

.report-option,
.report-option * {
    cursor: default;
}

.report-option:hover,
.report-option.isFocused,
.report-option.isSelected {
    background-color: #deebff;
}

.report-option .keyword-note {
    float: right;
}

.select-keyword input[type="text"] {
    height: 34px;
    line-height: 34px;
    margin: 0;
    position: relative;
    top: -2px;
}

/* .full-content-list-insights
----------------------------------------------------------------------------------------*/

.full-content-list-insights {
    position: relative;
    font-size: 0;
}

.full-content-list-insights .content-list-insight {
    position: relative;
    display: inline-block;
    vertical-align: top;
    border: solid 1px #d2d2d2;
    border-radius: 8px;
    width: 32%;
    height: 100%;
}

.full-content-list-insights .content-list-insight:last-child {
    margin-bottom: 20px;
}

.full-content-list-insights .content-list-insight.width-165 {
    width: 165px;
}

@media only screen and (max-width: 1045px) {

    .full-content-list-insights .content-list-insight,
    .full-content-list-insights .content-list-insight:nth-child(3n + 2),
    .full-content-list-insights .content-list-insight:nth-child(odd),
    .full-content-list-insights .content-list-insight:nth-child(even) {
        width: 45%;
    }
}

@media only screen and (max-width: 909px) {

    .full-content-list-insights .content-list-insight,
    .full-content-list-insights .content-list-insight:nth-child(3n + 2),
    .full-content-list-insights .content-list-insight:nth-child(odd),
    .full-content-list-insights .content-list-insight:nth-child(even) {
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
}

.full-content-list-insights .content-list-insight table {
    width: 100%;
    font-size: 15px;
    border-collapse: separate;
}

.full-content-list-insights .content-list-insight table tr td {
    padding-top: 5px;
    padding-bottom: 5px;
}

.full-content-list-insights {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 10px;
}

/* .full-content-list-relevant
----------------------------------------------------------------------------------------*/

.full-content-list-relevant {
    position: relative;
    overflow-y: hidden;
    border: solid 1px #eee;
    border-radius: 8px;
    padding: 16px;
}

.full-content-list-relevant table {
    width: 100%;
    font-size: 15px;
    border-collapse: separate;
    table-layout: fixed;
}

.full-content-list-relevant table tr td {
    padding-top: 6px;
    padding-bottom: 6px;
    overflow: hidden;
    white-space: nowrap;
}

.content-lined-graph {
    position: relative;
    height: 12px;
}

.content-lined-graph .content-flow {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.content-lined-graph .content-flow>div {
    position: absolute;
    top: 0;
    height: 100%;
}

.content-lined-graph .content-flow>.bad {
    opacity: 0.15;
    background-color: #e04f77;
}

.content-lined-graph .content-flow>.neutral {
    opacity: 0.2;
    background-color: #b6b2c9;
}

.content-lined-graph .content-flow>.good {
    opacity: 0.15;
    background-color: #27c986;
}

.content-lined-graph .content-data {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.content-lined-graph .content-data .line {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
}

.content-lined-graph .content-data .line .content {
    position: relative;
    width: 2px;
    height: 12px;
    border: solid 1px transparent;
    background-color: transparent;
    box-sizing: border-box;
}

.content-lined-graph .content-data .line.bad .content {
    border: solid 1px #e04f77;
    background-color: #e04f77;
}

.content-lined-graph .content-data .line.neutral .content {
    border: solid 1px #b6b2c9;
    background-color: #b6b2c9;
}

.content-lined-graph .content-data .line.good .content {
    border: solid 1px #27c986;
    background-color: #27c986;
}

.content-lined-graph .content-data .line .content:hover {
    z-index: 2;
    box-shadow: 0px 0px 0px 2px #3a79e6;
}

@media screen and (max-width: 650px) {
    .full-content-list-relevant table tr td.td-graph {
        display: none !important;
    }

    .full-content-list-relevant table tr td.td-name {
        width: auto !important;
    }

    .full-content-list-relevant table tr td.td-emotion {
        text-align: right;
        width: auto !important;
    }
}

/* #lined-graph-tooltip
----------------------------------------------------------------------------------------*/

#lined-graph-tooltip {
    z-index: 5;
    position: relative;
}

.bs-popover-bottom #lined-graph-tooltip {
    margin-top: 5px;
}

.bs-popover-top #lined-graph-tooltip {
    top: -5px;
}

/* .fake-loading-item
----------------------------------------------------------------------------------------*/

.fake-loading-item {
    position: relative;
    display: inline-block;
    height: 20px;
    background-color: #b6b2c9;
    border-radius: 4px;
}

/* .insightlist-expand-icon
----------------------------------------------------------------------------------------*/

.insightlist-expand-icon {
    position: absolute;
    right: 16px;
    bottom: 16px;
    color: #7f7f7f;
    cursor: pointer;
}

/* .responsive-keyword
----------------------------------------------------------------------------------------*/

.responsive-keyword {
    width: 120px;
}

@media only screen and (max-width: 1045px) {
    .responsive-keyword {
        width: 250px;
    }
}

@media only screen and (max-width: 909px) {
    .responsive-keyword {
        width: 300px;
    }
}

@media only screen and (max-width: 517px) {
    .responsive-keyword {
        width: 100%;
    }
}

/* claimed value indicators
----------------------------------------------------------------------------------------*/

.claimed-value-bckgrd.inDepth {
    padding: 16px;
    border-radius: 8px;
    border: solid 1px #eee;
    background-color: #fff;
    height: 100%;
}

.text-limit-6 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    word-break: break-word;
}

.positive-percent {
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-weight: 600;
}

/* colored score
----------------------------------------------------------------------------------------*/

.margin-top-minus-5 {
    margin-top: -5px;
}

.margin-top-minus-7 {
    margin-top: -7px;
}

.kpi-subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #7f7f7f;
    line-height: 19px;
}

.center-flex {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 35px;
}

.positive-percent {
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: #27c986;
}

.negative-percent {
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: #e04f77;
}

.positive-percent-subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #27c986;
}

.negative-percent-subtitle {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #e04f77;
}

.font-size-20-important {
    font-size: 20px !important;
}

.blured-score {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 21px;
    background-size: auto 32px;
    background-repeat: no-repeat;
    background-position: right center;
}

.blured-score.blured-data-1-1 {
    background-image: url("../../res/images/blurred-data/score/negative/1.png");
}

.blured-score.blured-data-1-2 {
    background-image: url("../../res/images/blurred-data/score/negative/2.png");
}

.blured-score.blured-data-1-3 {
    background-image: url("../../res/images/blurred-data/score/negative/3.png");
}

.blured-score.blured-data-2-1 {
    background-image: url("../../res/images/blurred-data/score/neutral/1.png");
}

.blured-score.blured-data-2-2 {
    background-image: url("../../res/images/blurred-data/score/neutral/2.png");
}

.blured-score.blured-data-2-3 {
    background-image: url("../../res/images/blurred-data/score/neutral/3.png");
}

.blured-score.blured-data-3-1 {
    background-image: url("../../res/images/blurred-data/score/positive/1.png");
}

.blured-score.blured-data-3-2 {
    background-image: url("../../res/images/blurred-data/score/positive/2.png");
}

.blured-score.blured-data-3-3 {
    background-image: url("../../res/images/blurred-data/score/positive/3.png");
}

/* rounded emotion
----------------------------------------------------------------------------------------*/

.content-rounded-emotion .round-emotion-color {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: #b6b2c9;
    margin-right: 6px;
}

.blured-emotion {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 130px;
    height: 21px;
    background-size: auto 32px;
    background-repeat: no-repeat;
    background-position: left center;
}

.blured-emotion.blured-data-1-1 {
    background-image: url("../../res/images/blurred-data/emotion/negative/1.png");
}

.blured-emotion.blured-data-1-2 {
    background-image: url("../../res/images/blurred-data/emotion/negative/2.png");
}

.blured-emotion.blured-data-1-3 {
    background-image: url("../../res/images/blurred-data/emotion/negative/3.png");
}

.blured-emotion.blured-data-2-1 {
    background-image: url("../../res/images/blurred-data/emotion/neutral/1.png");
}

.blured-emotion.blured-data-2-2 {
    background-image: url("../../res/images/blurred-data/emotion/neutral/2.png");
}

.blured-emotion.blured-data-2-3 {
    background-image: url("../../res/images/blurred-data/emotion/neutral/3.png");
}

.blured-emotion.blured-data-3-1 {
    background-image: url("../../res/images/blurred-data/emotion/positive/1.png");
}

.blured-emotion.blured-data-3-2 {
    background-image: url("../../res/images/blurred-data/emotion/positive/2.png");
}

.blured-emotion.blured-data-3-3 {
    background-image: url("../../res/images/blurred-data/emotion/positive/3.png");
}

/* share tooltip
----------------------------------------------------------------------------------------*/

.tooltip-share {
    width: 376px;
    height: 109px;
    border: solid 1px #979797;
    background-color: #fff;
    border-radius: 6px;
    margin-top: 10px;
}

.tooltip-share-text {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 600;
    text-align: left;
    color: #969696;
    margin: 23px 18px 14px;
}

.triangle-tooltip {
    height: 0;
    width: 0;
    border-right: 8px solid transparent;
    border-bottom: 9px solid #ffffff;
    border-left: 8px solid transparent;
    margin: auto;
    top: 3px;
    left: 187px;
    position: fixed;
}

.triangle-tooltip-border {
    height: 0;
    width: 0;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #979797;
    border-left: 8px solid transparent;
    margin: auto;
    top: 2px;
    left: 187px;
    position: fixed;
}

.padding-right-18 {
    padding-right: 18px;
}

.clipboard-toast {
    padding: 9px 14px 10px;
    border-radius: 20px;
    background-color: #e2ebfb;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    color: #3a79e6;
    margin-right: 23px;
}

.right-arrow-breadcrumb {
    background-image: url("../../res/images/breadcrumb/right-arrow-breadcrumb.svg");
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
}

#detailStory {
    font-size: 12px;
    font-weight: 500;
    color: #9e9e9e;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.classSelector.noHistory {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

/* End of file
----------------------------------------------------------------------------------------*/
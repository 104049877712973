#projectFilters {
  position: relative;
}
#projectFilters button:hover {
  cursor: pointer;
}
#projectFilters #filtersBtn {
  width: 105px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 8px;
  border: solid 1px transparent;
  border-radius: 6px;
  background-color: white;
  font-size: 15px;
  font-weight: 500;
  color: #3a79e6;
  transition: 150ms ease-in-out;
}
#projectFilters #filtersBtn #filters-arrow {
  transform: rotate(180deg);
}
#projectFilters #filtersBtn #filters-arrow.active {
  transform: rotate(0);
}
#projectFilters #filtersBtn.active {
  background-color: rgba(48, 110, 214, 0.04);
  border: solid 1px #3a79e6;
}
#projectFilters #filtersBtn:hover {
  cursor: pointer;
}
#projectFilters #filtersView {
  position: relative;
  width: calc(100% - 30px);
  top: 0;
  left: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  z-index: 1;
}
#projectFilters #filtersView .tag {
  height: 32px;
  max-width: 205px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  padding-right: 5px;
  border: solid 1px #306ed6;
  border-radius: 4px;
}
#projectFilters #filtersView .tag p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  color: #3a79e6;
  font-size: 15px;
}
#projectFilters #filtersView button {
  font-size: 15px;
  font-weight: 500;
  color: #3a79e6;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  margin-left: 8px;
}
#projectFilters #filters-content {
  height: auto;
  max-height: 500px;
  overflow-y: scroll;
  position: absolute;
  top: calc(100% + 10px);
  z-index: -2;
  background-color: #fff;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2509803922);
  transition: 150ms ease-in-out;
  transform: scale(0.5);
  transform-origin: center top;
  opacity: 0;
  visibility: hidden;
  padding: 20px 16px 14px 16px;
  width: 320px;
}
#projectFilters #filters-content #filters-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 6px;
  row-gap: 16px;
}
#projectFilters #filters-content #filters-box .filter {
  overflow-y: hidden;
  transition: 150ms ease-in-out;
  position: relative;
  height: 20px;
}
#projectFilters #filters-content #filters-box .filter #clickArea {
  content: "";
  width: 100%;
  height: 20px;
  position: absolute;
  top: 0;
  background-color: transparent;
  z-index: 1;
}
#projectFilters #filters-content #filters-box .filter #clickArea:hover {
  cursor: pointer;
}
#projectFilters #filters-content #filters-box .filter .lock {
  justify-content: start;
  gap: 10px;
}
#projectFilters #filters-content #filters-box .filter h3 {
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
}
#projectFilters #filters-content #filters-box .filter h3 span {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
#projectFilters #filters-content #filters-box .filter h3 img:last-child {
  transform: rotate(180deg);
}
#projectFilters #filters-content #filters-box .filter h3 img:last-child.active-arrow {
  transform: rotate(0);
}
#projectFilters #filters-content #filters-box .filter .filters {
  margin-left: 10px;
  margin-right: 10px;
}
#projectFilters #filters-content #filters-box .filter .filters .MuiFormControlLabel-label {
  display: flex;
  align-items: center;
}
#projectFilters #filters-content #filters-box .filter .filters img + span {
  margin-left: 8px;
}
#projectFilters #filters-content #filters-box .filter span.MuiCheckbox-root {
  padding: 4px 8px;
}
#projectFilters #filters-content #filters-box .filter.active-filter {
  height: auto;
}
#projectFilters #filters-content.active {
  transform: scale(1);
  opacity: 1;
  background-color: white;
  z-index: 2;
  visibility: visible;
}/*# sourceMappingURL=ProjectFilters.css.map */
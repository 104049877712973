/* Main Footer
----------------------------------------------------------------------------------------*/

.main-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}
.fake-main-footer {
    position: relative;
    opacity: 0;
    visibility: hidden;
}

/* End of file
----------------------------------------------------------------------------------------*/
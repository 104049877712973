@charset "utf-8";

/* reset
----------------------------------------------------------------------------------------*/

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  padding-top: 0 !important;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

html.reset,
html.reset body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html.height-100 {
  height: 100%;
  min-height: 100%;
}

.min-height-100vh {
  min-height: 100vh;
}

html.no-scroll,
html.no-scroll body,
html.no-scroll #main,
html.no-scroll #root,
.no-scroll {
  overflow: hidden !important;
}

html {
  min-height: 100%;
}

/* Color
----------------------------------------------------------------------------------------*/

.color-fff {
  color: #fff;
}

.color-000 {
  color: #000;
}

.color-F78F64 {
  color: #f78f64;
}

.color-9e9e9e {
  color: #9e9e9e;
}

/* Table
----------------------------------------------------------------------------------------*/

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.table-layout-fixed {
  table-layout: fixed;
}

table.width-percent-100 {
  width: 100% !important;
}

@media only screen and (max-width: 900px) {
  table.responsive-table-900 tr td {
    display: block;
    width: 100%;
  }

  .responsive-hidden-900,
  table tr td.responsive-hidden-900 {
    display: none !important;
  }
}

/* Font size (0 to 60px)
----------------------------------------------------------------------------------------*/

.font-size-0 {
  font-size: 0;
}

.font-size-1 {
  font-size: 1px;
}

.font-size-2 {
  font-size: 2px;
}

.font-size-3 {
  font-size: 3px;
}

.font-size-4 {
  font-size: 4px;
}

.font-size-5 {
  font-size: 5px;
}

.font-size-6 {
  font-size: 6px;
}

.font-size-7 {
  font-size: 7px;
}

.font-size-8 {
  font-size: 8px;
}

.font-size-9 {
  font-size: 9px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-17 {
  font-size: 17px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-19 {
  font-size: 19px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-21 {
  font-size: 21px;
}

.font-size-22 {
  font-size: 22px;
}

.font-size-23 {
  font-size: 23px;
}

.font-size-24 {
  font-size: 24px;
}

.font-size-25 {
  font-size: 25px;
}

.font-size-26 {
  font-size: 26px;
}

.font-size-27 {
  font-size: 27px;
}

.font-size-28 {
  font-size: 28px;
}

.font-size-29 {
  font-size: 29px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-31 {
  font-size: 31px;
}

.font-size-32 {
  font-size: 32px;
}

.font-size-33 {
  font-size: 33px;
}

.font-size-34 {
  font-size: 34px;
}

.font-size-35 {
  font-size: 35px;
}

.font-size-36 {
  font-size: 36px;
}

.font-size-37 {
  font-size: 37px;
}

.font-size-38 {
  font-size: 38px;
}

.font-size-39 {
  font-size: 39px;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-41 {
  font-size: 41px;
}

.font-size-42 {
  font-size: 42px;
}

.font-size-43 {
  font-size: 43px;
}

.font-size-44 {
  font-size: 44px;
}

.font-size-45 {
  font-size: 45px;
}

.font-size-46 {
  font-size: 46px;
}

.font-size-47 {
  font-size: 47px;
}

.font-size-48 {
  font-size: 48px;
}

.font-size-49 {
  font-size: 49px;
}

.font-size-50 {
  font-size: 50px;
}

.font-size-51 {
  font-size: 51px;
}

.font-size-52 {
  font-size: 52px;
}

.font-size-53 {
  font-size: 53px;
}

.font-size-54 {
  font-size: 54px;
}

.font-size-55 {
  font-size: 55px;
}

.font-size-56 {
  font-size: 56px;
}

.font-size-57 {
  font-size: 57px;
}

.font-size-58 {
  font-size: 58px;
}

.font-size-59 {
  font-size: 59px;
}

.font-size-60 {
  font-size: 60px;
}

.font-style-italic {
  font-style: italic;
}

/* Font weight
----------------------------------------------------------------------------------------*/

.font-weight-thin {
  font-weight: 100;
}

.font-weight-extra-light {
  font-weight: 200;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-extra-bold {
  font-weight: 800;
}

.font-weight-black {
  font-weight: 900;
}

.font-weight-100 {
  font-weight: 100;
}

.font-weight-200 {
  font-weight: 200;
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.font-weight-800 {
  font-weight: 800;
}

.font-weight-900 {
  font-weight: 900;
}

.font-weight-lighter {
  font-weight: lighter;
}

.font-weight-bolder {
  font-weight: bolder;
}

/* Line height (0 to 3)
----------------------------------------------------------------------------------------*/

.line-height-0 {
  line-height: 0;
}

.line-height-0-1 {
  line-height: 0.1;
}

.line-height-0-2 {
  line-height: 0.2;
}

.line-height-0-3 {
  line-height: 0.3;
}

.line-height-0-4 {
  line-height: 0.4;
}

.line-height-0-5 {
  line-height: 0.5;
}

.line-height-0-6 {
  line-height: 0.6;
}

.line-height-0-7 {
  line-height: 0.7;
}

.line-height-0-8 {
  line-height: 0.8;
}

.line-height-0-9 {
  line-height: 0.9;
}

.line-height-1 {
  line-height: 1;
}

.line-height-1-1 {
  line-height: 1.1;
}

.line-height-1-2 {
  line-height: 1.2;
}

.line-height-1-3 {
  line-height: 1.3;
}

.line-height-1-4 {
  line-height: 1.4;
}

.line-height-1-5 {
  line-height: 1.5;
}

.line-height-1-6 {
  line-height: 1.6;
}

.line-height-1-7 {
  line-height: 1.7;
}

.line-height-1-8 {
  line-height: 1.8;
}

.line-height-1-9 {
  line-height: 1.9;
}

.line-height-2 {
  line-height: 2;
}

.line-height-2-1 {
  line-height: 2.1;
}

.line-height-2-2 {
  line-height: 2.2;
}

.line-height-2-3 {
  line-height: 2.3;
}

.line-height-2-4 {
  line-height: 2.4;
}

.line-height-2-5 {
  line-height: 2.5;
}

.line-height-2-6 {
  line-height: 2.6;
}

.line-height-2-7 {
  line-height: 2.7;
}

.line-height-2-8 {
  line-height: 2.8;
}

.line-height-2-9 {
  line-height: 2.9;
}

.line-height-3 {
  line-height: 3;
}

.line-height-10 {
  line-height: 10px;
}

.line-height-15 {
  line-height: 15px;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-17 {
  line-height: 17px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-19 {
  line-height: 19px;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-30 {
  line-height: 30px;
}

.line-height-40 {
  line-height: 40px;
}

.line-height-50 {
  line-height: 50px;
}

.line-height-60 {
  line-height: 60px;
}

.line-height-70 {
  line-height: 70px;
}

.line-height-80 {
  line-height: 80px;
}

.line-height-90 {
  line-height: 90px;
}

.line-height-100 {
  line-height: 100px;
}

/* Width  
----------------------------------------------------------------------------------------*/
.width-60 {
  width: 60px;
}

.width-142 {
  width: 142px;
}

.width-330 {
  width: 330px;
}

.width-350 {
  width: 350px;
}

.width-520 {
  width: 520px;
}

.width-540 {
  width: 540px;
}

.width-565 {
  width: 565px;
}

.min-width-775 {
  min-width: 775px;
}

.max-width-565 {
  max-width: 565px;
}

.width-1440 {
  width: 3000px;
}

/* Height  
----------------------------------------------------------------------------------------*/
.height-34 {
  height: 34px;
}

.height-38 {
  height: 38px;
}

.height-50 {
  height: 50px;
}

/* Align items
----------------------------------------------------------------------------------------*/
.align-items-center {
  align-items: center;
}

/* Text align
----------------------------------------------------------------------------------------*/

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-align-inherit {
  text-align: inherit;
}

.text-align-initial {
  text-align: initial;
}

.text-align-justify {
  text-align: justify;
}

/* Text transform
----------------------------------------------------------------------------------------*/

.text-transform-uppercase {
  text-transform: uppercase;
}

.text-transform-capitalize {
  text-transform: capitalize;
}

.text-transform-lowercase {
  text-transform: lowercase;
}

.text-transform-none {
  text-transform: none;
}

.text-transform-full-width {
  text-transform: full-width;
}

/* Text decoration
----------------------------------------------------------------------------------------*/

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-overline {
  text-decoration: overline;
}

.text-decoration-dotted {
  text-decoration: dotted;
}

.text-decoration-wavy {
  text-decoration: wavy;
}

.text-decoration-initial {
  text-decoration: initial;
}

/* Text tools
----------------------------------------------------------------------------------------*/

.text-ellipsis {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  cursor: default;
}

.text-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-ellipsis-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-break-line {
  word-wrap: break-word;
  /* All browsers since IE 5.5+ */
  overflow-wrap: break-word;
  /* Renamed property in CSS3 draft spec */
  width: 100%;
}

.nl2br {
  white-space: pre-wrap;
}

/* Transitions
----------------------------------------------------------------------------------------*/

.transition-container,
.transition-container *,
.transition {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.transition-container-slow,
.transition-container-slow *,
.transition-slow {
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.transition-container-opacity,
.transition-container-opacity *,
.transition-opacity {
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.transition-container-none,
.transition-container-none *,
.transition-none {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

.transition-container-color,
.transition-container-color *,
.transition-color {
  -webkit-transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  -moz-transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  -o-transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  -ms-transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
  transition: background-color 0.3s ease, color 0.3s ease,
    border-color 0.3s ease;
}

/* Overflow
----------------------------------------------------------------------------------------*/

.overflow-visible {
  overflow: visible;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-unset {
  overflow: unset;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-x-unset {
  overflow-x: unset;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-unset {
  overflow-y: unset;
}

.overflow-y-auto {
  overflow-y: auto;
}

/* Display
----------------------------------------------------------------------------------------*/

.display-none {
  display: none;
}

.display-none-important {
  display: none !important;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.display-list-item {
  display: list-item;
}

.display-inline-table {
  display: inline-table;
}

.display-table {
  display: table;
}

.display-table-cell {
  display: table-cell;
}

.display-table-column {
  display: table-column;
}

.display-table-row {
  display: table-row;
}

.display-flex {
  display: flex;
}

.display-flex-important {
  display: flex !important;
}

.display-inline-flex {
  display: inline-flex;
}

.display-grid {
  display: grid;
}

.display-inline-grid {
  display: inline-grid;
}

/* Justify content
----------------------------------------------------------------------------------------*/

.justify-content-center {
  justify-content: center;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

/* Float
----------------------------------------------------------------------------------------*/

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-none {
  float: none;
}

.float-initial {
  float: initial;
}

.float-inherit {
  float: inherit;
}

/* Opacity
----------------------------------------------------------------------------------------*/

.opacity-0 {
  opacity: 0;
}

.opacity-0-1 {
  opacity: 0.1;
}

.opacity-0-2 {
  opacity: 0.2;
}

.opacity-0-3 {
  opacity: 0.3;
}

.opacity-0-4 {
  opacity: 0.4;
}

.opacity-0-5 {
  opacity: 0.5;
}

.opacity-0-6 {
  opacity: 0.6;
}

.opacity-0-7 {
  opacity: 0.7;
}

.opacity-0-8 {
  opacity: 0.8;
}

.opacity-0-9 {
  opacity: 0.9;
}

.opacity-1 {
  opacity: 1;
}

/* Vertical align
----------------------------------------------------------------------------------------*/

.vertical-align-baseline {
  vertical-align: baseline;
}

.vertical-align-sub {
  vertical-align: sub;
}

.vertical-align-super {
  vertical-align: super;
}

.vertical-align-text-top {
  vertical-align: text-top;
}

.vertical-align-text-bottom {
  vertical-align: text-bottom;
}

.vertical-align-middle {
  vertical-align: middle;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.vertical-align-inherit {
  vertical-align: inherit;
}

.vertical-align-initial {
  vertical-align: initial;
}

.vertical-align-unset {
  vertical-align: unset;
}

/* Vertical align tools
----------------------------------------------------------------------------------------*/

.content-vertical-inline-block {
  position: relative;
  font-size: 0;
}

.content-vertical-inline-block > * {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
}

/* Cursors
----------------------------------------------------------------------------------------*/

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-default-important {
  cursor: default !important;
}

.cursor-none {
  cursor: none;
}

.cursor-help {
  cursor: help;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

.cursor-wait {
  cursor: wait;
}

.cursor-cell {
  cursor: cell;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-text {
  cursor: text;
}

.cursor-vertical-text {
  cursor: vertical-text;
}

.cursor-alias {
  cursor: alias;
}

.cursor-copy {
  cursor: copy;
}

.cursor-move {
  cursor: move;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-n-resize {
  cursor: n-resize;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-s-resize {
  cursor: s-resize;
}

.cursor-w-resize {
  cursor: w-resize;
}

.cursor-ne-resize {
  cursor: ne-resize;
}

.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor-se-resize {
  cursor: se-resize;
}

.cursor-sw-resize {
  cursor: sw-resize;
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-ns-resize {
  cursor: ns-resize;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-nwse-resize {
  cursor: nwse-resize;
}

.cursor-zoom-in {
  cursor: pointer;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

/* Padding
----------------------------------------------------------------------------------------*/
.padding-0 {
  padding: 0 !important;
}

.padding-vertical-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.padding-vertical-4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.padding-vertical-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.padding-vertical-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-vertical-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.padding-vertical-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-vertical-25 {
  padding-top: 25px;
  padding-bottom: 25px;
}

.padding-vertical-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-vertical-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.padding-vertical-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.padding-vertical-45 {
  padding-top: 45px;
  padding-bottom: 45px;
}

.padding-vertical-50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.padding-vertical-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

.padding-vertical-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.padding-vertical-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.padding-vertical-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding-horizontal-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.padding-horizontal-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-horizontal-14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.padding-horizontal-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.padding-horizontal-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.padding-horizontal-25 {
  padding-left: 25px;
  padding-right: 25px;
}

.padding-horizontal-30 {
  padding-left: 30px;
  padding-right: 30px;
}

.padding-horizontal-35 {
  padding-left: 35px;
  padding-right: 35px;
}

.padding-horizontal-40 {
  padding-left: 40px;
  padding-right: 40px;
}

.padding-horizontal-45 {
  padding-left: 45px;
  padding-right: 45px;
}

.padding-horizontal-50 {
  padding-left: 50px;
  padding-right: 50px;
}

.padding-horizontal-60 {
  padding-left: 60px;
  padding-right: 60px;
}

.padding-horizontal-70 {
  padding-left: 70px;
  padding-right: 70px;
}

.padding-horizontal-80 {
  padding-left: 80px;
  padding-right: 80px;
}

.padding-horizontal-90 {
  padding-left: 80px;
  padding-right: 90px;
}

.padding-5 {
  padding: 5px;
}

.padding-10 {
  padding: 10px;
}

.padding-15 {
  padding: 15px;
}

.padding-16 {
  padding: 16px;
}

.padding-20 {
  padding: 20px;
}

.padding-25 {
  padding: 25px;
}

.padding-30 {
  padding: 30px;
}

.padding-35 {
  padding: 35px;
}

.padding-40 {
  padding: 40px;
}

.padding-45 {
  padding: 45px;
}

.padding-50 {
  padding: 50px;
}

.padding-top-0 {
  padding-top: 0;
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-15 {
  padding-top: 15px;
}

.padding-top-18 {
  padding-top: 18px;
}

.padding-top-20 {
  padding-top: 20px;
}

.padding-top-25 {
  padding-top: 25px;
}

.padding-top-26 {
  padding-top: 26px;
}

.padding-top-30 {
  padding-top: 30px;
}

.padding-top-35 {
  padding-top: 35px;
}

.padding-top-36 {
  padding-top: 36px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-45 {
  padding-top: 45px;
}

.padding-top-50 {
  padding-top: 50px;
}

.padding-top-60 {
  padding-top: 60px;
}

.padding-top-70 {
  padding-top: 70px;
}

.padding-top-80 {
  padding-top: 80px;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

.padding-bottom-5 {
  padding-bottom: 5px;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.padding-bottom-14 {
  padding-bottom: 14px;
}

.padding-bottom-15 {
  padding-bottom: 15px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-bottom-25 {
  padding-bottom: 25px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-35 {
  padding-bottom: 35px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-bottom-45 {
  padding-bottom: 45px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

.padding-bottom-60 {
  padding-bottom: 60px;
}

.padding-bottom-70 {
  padding-bottom: 70px;
}

.padding-bottom-80 {
  padding-bottom: 80px;
}

.padding-left-0 {
  padding-left: 0;
}

.padding-left-4 {
  padding-left: 4px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-left-10 {
  padding-left: 10px;
}

.padding-left-15 {
  padding-left: 15px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-left-25 {
  padding-left: 25px;
}

.padding-left-30 {
  padding-left: 30px;
}

.padding-left-35 {
  padding-left: 35px;
}

.padding-left-40 {
  padding-left: 40px;
}

.padding-left-45 {
  padding-left: 45px;
}

.padding-left-50 {
  padding-left: 50px;
}

.padding-left-60 {
  padding-left: 60px;
}

.padding-left-100 {
  padding-left: 100px;
}

.padding-right-0 {
  padding-right: 0;
}

.padding-right-5 {
  padding-right: 5px;
}

.padding-right-8 {
  padding-right: 8px;
}

.padding-right-7 {
  padding-right: 7px;
}

.padding-right-10 {
  padding-right: 10px;
}

.padding-right-15 {
  padding-right: 15px;
}

.padding-right-20 {
  padding-right: 20px;
}

.padding-right-25 {
  padding-right: 25px;
}

.padding-right-30 {
  padding-right: 30px;
}

.padding-right-35 {
  padding-right: 35px;
}

.padding-right-40 {
  padding-right: 40px;
}

.padding-right-45 {
  padding-right: 45px;
}

.padding-right-50 {
  padding-right: 50px;
}

.padding-right-60 {
  padding-right: 60px;
}

/* Margin
----------------------------------------------------------------------------------------*/

.margin-vertical-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.margin-vertical-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.margin-vertical-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-vertical-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.margin-vertical-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-vertical-25 {
  margin-top: 25px;
  margin-bottom: 25px;
}

.margin-vertical-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.margin-vertical-35 {
  margin-top: 35px;
  margin-bottom: 35px;
}

.margin-vertical-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.margin-vertical-45 {
  margin-top: 45px;
  margin-bottom: 45px;
}

.margin-vertical-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.margin-horizontal-2 {
  margin-left: 2px;
  margin-right: 2px;
}

.margin-horizontal-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.margin-horizontal-10 {
  margin-left: 10px;
  margin-right: 10px;
}

.margin-horizontal-15 {
  margin-left: 15px;
  margin-right: 15px;
}

.margin-horizontal-20 {
  margin-left: 20px;
  margin-right: 20px;
}

.margin-horizontal-25 {
  margin-left: 25px;
  margin-right: 25px;
}

.margin-horizontal-30 {
  margin-left: 30px;
  margin-right: 30px;
}

.margin-horizontal-35 {
  margin-left: 35px;
  margin-right: 35px;
}

.margin-horizontal-40 {
  margin-left: 40px;
  margin-right: 40px;
}

.margin-horizontal-45 {
  margin-left: 45px;
  margin-right: 45px;
}

.margin-horizontal-50 {
  margin-left: 50px;
  margin-right: 50px;
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}

.margin-15 {
  margin: 15px;
}

.margin-20 {
  margin: 20px;
}

.margin-25 {
  margin: 25px;
}

.margin-30 {
  margin: 30px;
}

.margin-35 {
  margin: 35px;
}

.margin-40 {
  margin: 40px;
}

.margin-45 {
  margin: 45px;
}

.margin-50 {
  margin: 50px;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-1 {
  margin-top: 2px;
}

.margin-top-3 {
  margin-top: 3px;
}

.margin-top-4 {
  margin-top: 4px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-6 {
  margin-top: 6px;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-12 {
  margin-top: 12px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-17 {
  margin-top: 17px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-top-35 {
  margin-top: 35px;
}

.margin-top-36 {
  margin-top: 36px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-45 {
  margin-top: 45px;
}

.margin-top-50 {
  margin-top: 50px;
}

.margin-top-68 {
  margin-top: 68px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-4 {
  margin-bottom: 4px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-35 {
  margin-bottom: 35px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.margin-bottom-45 {
  margin-bottom: 45px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-55 {
  margin-bottom: 55px;
}

.margin-left-0 {
  margin-left: 0;
}

.margin-left-2 {
  margin-left: 2px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-8 {
  margin-left: 8px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-12 {
  margin-left: 12px;
}

.margin-left-15 {
  margin-left: 15px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-25 {
  margin-left: 25px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-35 {
  margin-left: 35px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-left-45 {
  margin-left: 45px;
}

.margin-left-50 {
  margin-left: 50px;
}

.margin-right-0 {
  margin-right: 0;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-12 {
  margin-right: 12px;
}

.margin-right-14 {
  margin-right: 14px;
}

.margin-right-15 {
  margin-right: 15px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-25 {
  margin-right: 25px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-35 {
  margin-right: 35px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-right-45 {
  margin-right: 45px;
}

.margin-right-50 {
  margin-right: 50px;
}

.margin-right-60 {
  margin-right: 60px;
}

/* Max width
----------------------------------------------------------------------------------------*/
.max-width-500 {
  max-width: 500px;
}

/* Margin Tools
----------------------------------------------------------------------------------------*/

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

/* Positions minimal
----------------------------------------------------------------------------------------*/

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

/* Position
----------------------------------------------------------------------------------------*/

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

/* Position Tools
----------------------------------------------------------------------------------------*/

.position-absolute-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.position-absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.position-absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.position-absolute-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.position-absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.position-absolute-vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.position-fixed-top-left {
  position: fixed;
  top: 0;
  left: 0;
}

.position-fixed-top-right {
  position: fixed;
  top: 0;
  right: 0;
}

.position-fixed-bottom-right {
  position: fixed;
  bottom: 0;
  right: 0;
}

.position-fixed-bottom-left {
  position: fixed;
  bottom: 0;
  left: 0;
}

.position-absolute-inner {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

/* Background
----------------------------------------------------------------------------------------*/

.background-color-fff {
  background-color: #ffffff;
}

.background-color-f1f1f1 {
  background-color: #f1f1f1;
}

.background-color-f2f2f2 {
  background-color: #f2f2f2;
}

.background-color-f3f3f3 {
  background-color: #f3f3f3;
}

.background-color-f4f4f4 {
  background-color: #f4f4f4;
}

.background-color-f5f5f5 {
  background-color: #f5f5f5;
}

.background-color-f6f6f6 {
  background-color: #f6f6f6;
}

.background-color-f7f7f7 {
  background-color: #f7f7f7;
}

.background-color-f8f8f8 {
  background-color: #f8f8f8;
}

.background-color-f9f9f9 {
  background-color: #f9f9f9;
}

.background-color-000 {
  background-color: #000000;
}

.background-image-cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.background-image-contain {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.background-image {
  background-position: center center;
  background-repeat: no-repeat;
}

/* Box sizing
----------------------------------------------------------------------------------------*/

.box-sizing-border-box {
  box-sizing: border-box;
}

.box-sizing-content-box {
  box-sizing: content-box;
}

/* Border radius
----------------------------------------------------------------------------------------*/

.border-radius-0 {
  border-radius: 0;
}

.border-radius-1 {
  border-radius: 1px;
}

.border-radius-2 {
  border-radius: 2px;
}

.border-radius-3 {
  border-radius: 3px;
}

.border-radius-4 {
  border-radius: 4px;
}

.border-radius-5 {
  border-radius: 5px;
}

.border-radius-6 {
  border-radius: 6px;
}

.border-radius-7 {
  border-radius: 7px;
}

.border-radius-8 {
  border-radius: 8px;
}

.border-radius-9 {
  border-radius: 9px;
}

.border-radius-10 {
  border-radius: 10px;
}

.border-radius-11 {
  border-radius: 11px;
}

.border-radius-12 {
  border-radius: 12px;
}

.border-radius-13 {
  border-radius: 13px;
}

.border-radius-14 {
  border-radius: 14px;
}

.border-radius-15 {
  border-radius: 15px;
}

.border-radius-16 {
  border-radius: 16px;
}

.border-radius-17 {
  border-radius: 17px;
}

.border-radius-18 {
  border-radius: 18px;
}

.border-radius-19 {
  border-radius: 19px;
}

.border-radius-20 {
  border-radius: 20px;
}

.border-radius-21 {
  border-radius: 21px;
}

.border-radius-22 {
  border-radius: 22px;
}

.border-radius-23 {
  border-radius: 23px;
}

.border-radius-24 {
  border-radius: 24px;
}

.border-radius-25 {
  border-radius: 25px;
}

.border-radius-26 {
  border-radius: 26px;
}

.border-radius-27 {
  border-radius: 27px;
}

.border-radius-28 {
  border-radius: 28px;
}

.border-radius-29 {
  border-radius: 29px;
}

.border-radius-30 {
  border-radius: 30px;
}

.border-radius-31 {
  border-radius: 31px;
}

.border-radius-32 {
  border-radius: 32px;
}

.border-radius-33 {
  border-radius: 33px;
}

.border-radius-34 {
  border-radius: 34px;
}

.border-radius-35 {
  border-radius: 35px;
}

.border-radius-36 {
  border-radius: 36px;
}

.border-radius-37 {
  border-radius: 37px;
}

.border-radius-38 {
  border-radius: 38px;
}

.border-radius-39 {
  border-radius: 39px;
}

.border-radius-40 {
  border-radius: 40px;
}

.border-radius-41 {
  border-radius: 41px;
}

.border-radius-42 {
  border-radius: 42px;
}

.border-radius-43 {
  border-radius: 43px;
}

.border-radius-44 {
  border-radius: 44px;
}

.border-radius-45 {
  border-radius: 45px;
}

.border-radius-46 {
  border-radius: 46px;
}

.border-radius-47 {
  border-radius: 47px;
}

.border-radius-48 {
  border-radius: 48px;
}

.border-radius-49 {
  border-radius: 49px;
}

.border-radius-50 {
  border-radius: 50px;
}

.border-radius-square {
  border-radius: 0;
}

.border-radius-round {
  border-radius: 50%;
}

/* Button, Input, Textarea
----------------------------------------------------------------------------------------*/

.button {
  line-height: 34px;
  border-radius: 17px;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  position: relative;
  border: none;
  background-color: #0070f0;
  box-sizing: border-box;
  cursor: pointer;
  outline: 0;
  padding: 0 15px;
  text-decoration: none;
  display: inline-block;
}

.button:not(:disabled):hover {
  background-color: #0075ff;
}

.button.height-26 {
  line-height: 26px;
  border-radius: 13px;
  font-size: 13px;
}

.hbutton.height-40 {
  line-height: 40px;
  border-radius: 20px;
  font-size: 16px;
}

.button:disabled {
  opacity: 0.5;
  cursor: default;
}

.button.loading.loader-position-right:disabled,
.button.loading.loader-position-left:disabled {
  background-color: #d2d2d2;
  color: #fff;
  opacity: 1;
}

select {
  border: 1px solid #c8c8c8;
  padding: 7px;
  background-color: #fff;
}

input,
select,
textarea,
button,
.button {
  -webkit-appearance: none;
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

input:focus,
textarea:focus {
  outline: 0;
  box-shadow: none;
}

input.width-percent-100,
textarea.width-percent-100,
button.width-percent-100,
.button.width-percent-100 {
  width: 100% !important;
}

/* Z index
----------------------------------------------------------------------------------------*/

.z-index-min {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

.z-index-6 {
  z-index: 6;
}

.z-index-7 {
  z-index: 7;
}

.z-index-8 {
  z-index: 8;
}

.z-index-9 {
  z-index: 9;
}

.z-index-10 {
  z-index: 10;
}

.z-index-max {
  z-index: 20;
}

/* Other Tools
----------------------------------------------------------------------------------------*/

.border-none {
  border: none;
}

.border-none-important {
  border: none !important;
}

.box-shadow-none {
  box-shadow: none;
}

.img-100 {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 100%;
  min-width: 100%;
  height: auto;
}

/* Column
----------------------------------------------------------------------------------------*/

.content-column {
  position: relative;
}

.content-column:not(.no-hidden) {
  overflow: hidden;
}

.content-column.no-hidden::after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
}

.column-percent-10,
.column-percent-20,
.column-percent-25,
.column-percent-30,
.column-percent-33,
.column-percent-34,
.column-percent-35,
.column-percent-39,
.column-percent-40,
.column-percent-43,
.column-percent-45,
.column-percent-48,
.column-percent-50,
.column-percent-55,
.column-percent-57,
.column-percent-60,
.column-percent-61,
.column-percent-65,
.column-percent-66,
.column-percent-70,
.column-percent-75,
.column-percent-80,
.column-percent-90 {
  position: relative;
  float: left;
  box-sizing: border-box;
}

.column-percent-10.float-right,
.column-percent-20.float-right,
.column-percent-25.float-right,
.column-percent-30.float-right,
.column-percent-33.float-right,
.column-percent-34.float-right,
.column-percent-35.float-right,
.column-percent-39.float-right,
.column-percent-40.float-right,
.column-percent-43.float-right,
.column-percent-45.float-right,
.column-percent-48.float-right,
.column-percent-50.float-right,
.column-percent-55.float-right,
.column-percent-57.float-right,
.column-percent-60.float-right,
.column-percent-61.float-right,
.column-percent-65.float-right,
.column-percent-66.float-right,
.column-percent-70.float-right,
.column-percent-75.float-right,
.column-percent-80.float-right,
.column-percent-90.float-right {
  float: right;
}

.column-percent-10 {
  width: 10%;
}

.column-percent-20 {
  width: 20%;
}

.column-percent-25 {
  width: 25%;
}

.column-percent-30 {
  width: 30%;
}

.column-percent-33 {
  width: 33%;
}

.column-percent-34 {
  width: 34%;
}

.column-percent-35 {
  width: 35%;
}

.column-percent-39 {
  width: 39%;
}

.column-percent-40 {
  width: 40%;
}

.column-percent-43 {
  width: 43%;
}

.column-percent-45 {
  width: 45%;
}

.column-percent-48 {
  width: 48%;
}

.column-percent-50 {
  width: 50%;
}

.column-percent-55 {
  width: 55%;
}

.column-percent-57 {
  width: 57%;
}

.column-percent-60 {
  width: 60%;
}

.column-percent-61 {
  width: 61%;
}

.column-percent-65 {
  width: 65%;
}

.column-percent-66 {
  width: 66%;
}

.column-percent-70 {
  width: 70%;
}

.column-percent-75 {
  width: 75%;
}

.column-percent-80 {
  width: 80%;
}

.column-percent-90 {
  width: 90%;
}

.column-percent-95 {
  width: 95%;
}

.column-percent-75-no-float {
  width: calc(75% - 30px);
}

/* Responsive column
----------------------------------------------------------------------------------------*/

@media only screen and (max-width: 900px) {
  .column-percent-10.responsive-900,
  .column-percent-20.responsive-900,
  .column-percent-25.responsive-900,
  .column-percent-30.responsive-900,
  .column-percent-33.responsive-900,
  .column-percent-35.responsive-900,
  .column-percent-39.responsive-900,
  .column-percent-40.responsive-900,
  .column-percent-43.responsive-900,
  .column-percent-45.responsive-900,
  .column-percent-48.responsive-900,
  .column-percent-50.responsive-900,
  .column-percent-55.responsive-900,
  .column-percent-57.responsive-900,
  .column-percent-60.responsive-900,
  .column-percent-61.responsive-900,
  .column-percent-65.responsive-900,
  .column-percent-66.responsive-900,
  .column-percent-70.responsive-900,
  .column-percent-75.responsive-900,
  .column-percent-80.responsive-900,
  .column-percent-90.responsive-900 {
    width: 100% !important;
    float: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .column-percent-10.hidden-900,
  .column-percent-20.hidden-900,
  .column-percent-25.hidden-900,
  .column-percent-30.hidden-900,
  .column-percent-33.hidden-900,
  .column-percent-35.hidden-900,
  .column-percent-39.hidden-900,
  .column-percent-40.hidden-900,
  .column-percent-43.hidden-900,
  .column-percent-45.hidden-900,
  .column-percent-48.hidden-900,
  .column-percent-50.hidden-900,
  .column-percent-55.hidden-900,
  .column-percent-57.hidden-900,
  .column-percent-60.hidden-900,
  .column-percent-61.hidden-900,
  .column-percent-65.hidden-900,
  .column-percent-66.hidden-900,
  .column-percent-70.hidden-900,
  .column-percent-75.hidden-900,
  .column-percent-80.hidden-900,
  .column-percent-90.hidden-900 {
    display: none !important;
  }
}

/* Iframe Tools
----------------------------------------------------------------------------------------*/

.content-iframe {
  position: relative;
  width: 100%;
  display: block;
  overflow: hidden;
}

.content-iframe img {
  width: 100%;
  display: block;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
}

.content-iframe iframe {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 2;
}

.content-iframe .iframe-picture {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 2;
  background-size: contain;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
}

/* Central content
----------------------------------------------------------------------------------------*/

.central-content {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.central-content.no-responsive {
  width: 1200px;
}

@media only screen and (max-width: 1240px) {
  .central-content:not(.no-responsive-margin) {
    margin: 0 20px;
  }
}

.central-content-1280 {
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
}

.central-content-1280.no-responsive {
  width: 1280px;
}

@media only screen and (max-width: 1320px) {
  .central-content-1280:not(.no-responsive-margin) {
    margin: 0 20px;
  }
}

.central-content-994 {
  position: relative;
  max-width: 994px;
  margin: 0 auto;
}

.central-content-994.no-responsive {
  width: 994px;
}

@media only screen and (max-width: 1034px) {
  .central-content-994:not(.no-responsive-margin) {
    margin: 0 20px;
  }
}

.central-content-846 {
  position: relative;
  max-width: 846px;
  margin: 0 auto;
}

.central-content-846.no-responsive {
  width: 846px;
}

@media only screen and (max-width: 886px) {
  .central-content-846:not(.no-responsive-margin) {
    margin: 0 20px;
  }
}

.central-content-786 {
  position: relative;
  max-width: 786px;
  margin: 0 auto;
}

.central-content-786.no-responsive {
  width: 786px;
}

@media only screen and (max-width: 826px) {
  .central-content-786:not(.no-responsive-margin) {
    margin: 0 20px;
  }
}

.central-content-550 {
  position: relative;
  max-width: 550px;
  margin: 0 auto;
}

.central-content-550.no-responsive {
  width: 550px;
}

@media only screen and (max-width: 590px) {
  .central-content-550:not(.no-responsive-margin) {
    margin: 0 20px;
  }
}

/* Link Tools
----------------------------------------------------------------------------------------*/

.link-absolute,
.link-absolute:hover,
.link-on-hover,
.link-on-hover:hover,
.link-global,
.link-global:hover,
.link,
.link:focus {
  outline: none;
}

.underline-on-hover {
  cursor: pointer;
  text-decoration: none;
}

.link-on-hover {
  cursor: pointer;
  text-decoration: none;
}

.underline-on-hover:hover,
.link-on-hover:hover {
  text-decoration: underline;
}

.opacity-on-hover:hover {
  opacity: 0.8;
}

.link-absolute {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 2;
}

.link-global {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
}

/* Loading Spinner Tool
----------------------------------------------------------------------------------------*/

.loading-spinner,
.loading-spinner:after {
  border-radius: 50%;
  height: 2.2rem;
  width: 2.2rem;
}

.loading-spinner {
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: loading-spinner-animation;
  -webkit-animation-timing-function: linear;
  -webkit-transform: translateZ(0);
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: loading-spinner-animation;
  animation-timing-function: linear;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-left-color: #19171c;
  position: relative;
  transform: translateZ(0);
}

.loading-spinner-26 {
  width: 26px;
  height: 26px;
}

.loading-spinner-16 {
  width: 16px;
  height: 16px;
}

.loading-spinner.centered {
  margin-right: auto;
  margin-left: auto;
}

.loading-spinner-small {
  height: 1.6rem;
  width: 1.6rem;
}

.loading-spinner-large {
  height: 2.8rem;
  width: 2.8rem;
}

.loading-spinner-inherit-color {
  border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15)
    currentColor;
}

/* loading-spinner-animation */

@-webkit-keyframes loading-spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes loading-spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-4 {
  gap: 4px;
}

.gap-5 {
  gap: 4px;
}

.gap-7 {
  gap: 7px;
}

.gap-12 {
  gap: 12px;
}

@media only screen and (max-width: 768px) {
  .responsive-emptyState #EmptyState-box + div {
    display: none;
  }

  .responsive-emptyState #EmptyState-box {
    width: 100%;
  }
}

.hover-hand:hover {
  cursor: pointer;
}

/* End of file
----------------------------------------------------------------------------------------*/

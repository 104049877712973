.card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  width: 330px;
  height: fit-content;

  img {
    width: 100%;
  }
}
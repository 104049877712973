.banner-container {
  position: relative;
}

.banner-titles {
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-align: left;
  color: #616161;
  z-index: 9;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.banner-name {
  font-size: 14px;
  width: 150px;
  top: 120px;
  left: 45px;
}

.banner-product-category {
  width: 170px;
  top: 72px;
  left: 45px;
}

@media screen and (max-width: 900px) {
  .responsive-flex {
    flex-direction: column;
    align-items: center;
  }

  .responsive-full-width {
    max-width: 100%;
  }

  .banner-container {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .responsive-banner {
    width: 300px !important;
  }

  .banner-name {
    width: 90px;
    top: 127px;
    left: 52px;
    font-size: 9px;
  }

  .banner-product-category {
    width: 120px;
    top: 165px;
    left: 52px;
    font-size: 9px;
  }
}
